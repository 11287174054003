import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../other/SideBar";
import NavBar from "../other/NavBar";
import {
  GetOrderDetailsURL,
  UpdateLiveOrdersStatusURL,
  LiveOrderNewPaymentURL,
  ScriptId,
  ScriptSRC,
  GetOrderKOTDetailsURL,
  CancelOrderURL,
  LiveOrderDuePaymentSubmitURl,
  paymentMethodChangeURl,
  orderSplitBillCreationURL,
  GetOrderBillsDetailsURL,
} from "../../assets/api/BaseURL";
import LoadingModal from "../../loader/LoadingModal";
import printJS from "print-js";
import Notification from "../other/Notification";
import { useToast } from "../../../context/ToastContext";

const OrderDetails = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [orderID, setOrderID] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [data, setData] = useState({});

  const [flag, setFlag] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [SCHRate, setSCHRate] = useState(0);
  const [discountRate, setDiscountRate] = useState();
  const [CGSTRate, setCGSTRate] = useState(0);
  const [SGSTRate, setSGSTRate] = useState(0);
  const [discountAmount, setDiscountAmount] = useState();
  const [schAmount, setSchAmount] = useState();
  const [cgstAmount, setCGSTAmount] = useState();
  const [sgstAmount, setSGSTAmount] = useState();
  const [totalAmountPaid, setTotalAmountPaid] = useState(0);
  const [totalAmountWithoutRoundOff, setTotalAmountWithoutRoundOff] = useState(0);

  // const [taxDetails, setTaxDetails] = useState([]);

  const [isModal, setIsModal] = useState(false);
  const [isBillsModal, setIsBillsModal] = useState(false);

  const [dataKOTDetails, setDataKOTDetails] = useState([]);
  const [printKOTDetails2, setPrintKOTDetails2] = useState(null);

  const [dataBillDetails, setBillDetails] = useState([]);
  const [printBillDetails2, setPrintBillDetails2] = useState(null);


  const [isCancelOrderModal, setIsCancelOrderModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const maxCharacterLimit = 200;

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const formatTimestamp = (timestampStr) => {
    const timestamp = parseInt(timestampStr, 10);
    const dateObject = new Date(timestamp * 1000);

    const day = dateObject.getDate();
    const month = dateObject.toLocaleString("default", { month: "short" });
    const year = dateObject.getFullYear();
    let hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";

    if (hours > 12) {
      hours -= 12;
    }

    return `${day} ${month} ${year}, ${hours}:${minutes
      .toString()
      .padStart(2, "0")} ${ampm}`;
  };

  const formatTimestampForDate = (timestampStr) => {
    const timestamp = parseInt(timestampStr, 10);
    const dateObject = new Date(timestamp * 1000);

    const day = dateObject.getDate();
    const month = dateObject.toLocaleString("default", { month: "short" });
    const year = dateObject.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const formatTimestampForTime = (timestampStr) => {
    const timestamp = parseInt(timestampStr, 10);
    const dateObject = new Date(timestamp * 1000);

    let hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";

    if (hours > 12) {
      hours -= 12;
    }

    return `${hours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
  };

  const capitalizeFirstLetter = (str) => {
    if (str) {
      const words = str.split(" ");

      const capitalizedWords = words.map((word) => {
        if (word.length === 0) {
          return "";
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      });
      const capitalizedString = capitalizedWords.join(" ");
      return capitalizedString;
    }
  };

  // const handleProductNameClick = (e, id) => {
  //   e.preventDefault();
  //   localStorage.setItem("productID", id);
  //   navigate(`/edit_product`);
  // };

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate("/orders");
  };

  const handlePrintBill = async () => {
    printJS({
      printable: "contentToPrintBill",
      type: "html",
      targetStyles: ["*"],
      font_size: '8pt'
    });
  };

  const handlePrintKOTClick = async () => {
    try {
      const response = await axios.get(GetOrderKOTDetailsURL, {
        params: {
          order_id: orderID,
        },
        headers: { authorization: `Bearer ${token}` },
      });

      if (response.status === 200 && response.data.data) {
        setDataKOTDetails(response.data.data);
        setIsModal(true);
      } else if (response.status === 200 && response.data.message) {
        handlePrintKOT();
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
  };

  const handlePrintKOT = async () => {
    printJS({
      printable: "contentToPrintKOT",
      type: "html",
      targetStyles: ["*"],
    });
  };


  const handlePrintBillClick = async () => {
    try {
      const response = await axios.get(GetOrderBillsDetailsURL, {
        params: {
          order_id: orderID,
        },
        headers: { authorization: `Bearer ${token}` },
      });

      if (response.status === 200 && response.data.data) {
        setBillDetails(response.data.data);
        setIsBillsModal(true);
      } else if (response.status === 200 && response.data.message) {
        handlePrintBill();
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
  };


  const handleChangeOrderStatus = async (e, id, status) => {
    e.preventDefault();

    const data = { id: id, status: status };
    setIsLoading(true);
    try {
      const response = await axios({
        method: "put",
        url: UpdateLiveOrdersStatusURL,
        data: data,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setFlag(!flag);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
    setIsLoading(false);
  };


  const handleCancelOrderReasonChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= maxCharacterLimit) {
      setCancelReason(inputValue);
    }
  };

  const handleCancelOrderClick = () => {
    setCancelReason("");
    setIsCancelOrderModal(true);
  };

  const handleCancelOrder = async (e) => {
    e.preventDefault();

    if (cancelReason === "" || cancelReason.trim() === "") {
      addToast("error", "Please enter reason first");
    } else {
      const dataToSend = {
        order_id: orderID,
        cancelled_reason: cancelReason,
      };

      try {
        const response = await axios({
          method: "put",
          url: CancelOrderURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          addToast("success", response.data.message);
          setIsCancelOrderModal(false);
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        console.log(error);
      }
    }
  };

  function isValidPrice(price) {
    const pricePattern = /^\d+(\.\d{1,2})?$/;

    if (!pricePattern.test(price)) {
      // return parseFloat(price).toFixed(2);
      return price;

    }
    return price;
  }

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    if (localStorage.getItem('round_off_amount') === "1") {
      setOnlySavePaymentRoundUpCheck(true)

    }
  }, [token]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    var id = ''
    if (urlParams.has("id")) {
      id = urlParams.get("id");
      setOrderID(id)
    }
    else {
      setOrderID(localStorage.getItem("orderID"));

    }


  }, [orderID]);

  useEffect(() => {
    if (localStorage.getItem("currencySymbol")) {
      setCurrencySymbol(localStorage.getItem("currencySymbol"));
    }
  }, []);

  useEffect(() => {
    if (token && orderID) {
      async function getData() {
        setIsLoading(true);
        try {
          const response = await axios.get(GetOrderDetailsURL, {
            params: {
              id: orderID,
            },
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            setData(response.data.data);

            if (response.data.data.payment_status === 1 || response.data.data.payment_status === 3) {
              setOnlySavePaymentRoundUpAmount(0)
            }

            setPaymentDetailsData(response.data.data.partialPaymentDetail)

            if (response.data.data.partialPaymentDetail) {
              let amount = 0;

              response.data.data.partialPaymentDetail.forEach((i) => {
                if (i.paidAmount) {
                  amount += parseFloat(i.paidAmount);
                }
              });

              setPartialPaymentDetailTotalAmount(amount);
            }

            if (
              response.data.data.service_charge_details.percentage !== "0" &&
              !isNaN(
                parseFloat(response.data.data.service_charge_details.percentage)
              ) &&
              isFinite(response.data.data.service_charge_details.percentage)
            ) {
              setSCHRate(
                parseFloat(response.data.data.service_charge_details.percentage)
              );
            }

            if (
              response.data.data.discount_rate !== "0" &&
              !isNaN(parseFloat(response.data.data.discount_rate)) &&
              isFinite(response.data.data.discount_rate)
            ) {
              setDiscountRate(parseFloat(response.data.data.discount_rate));
            } else {
              setDiscountRate();
            }

            if (
              response.data.data.tax_details &&
              response.data.data.tax_details.length !== 0
            ) {
              response.data.data.tax_details.forEach((item) => {
                if (item.name === "CGST") {
                  setCGSTRate(parseFloat(item.percentage));
                } else if (item.name === "SGST") {
                  setSGSTRate(parseFloat(item.percentage));
                }
              });
            }
            if (response.data.data.splitBillsData) {
              setSplitBillData(response.data.data.splitBillsData)
              setSplitBillNumber(response.data.data.splitBillsData.length + 1)
            }
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, orderID, flag, addToast]);




  const [cartProductPackingCharges, setCartProductPackingCharges] = useState(0);

  const [CGSTTaxT1, setCGSTTaxt1] = useState(0);
  const [CGSTTaxT2, setCGSTTaxt2] = useState(0);
  const [CGSTTaxT3, setCGSTTaxt3] = useState(0);
  const [CGSTTaxT4, setCGSTTaxt4] = useState(0);
  const [CGSTTaxT5, setCGSTTaxt5] = useState(0);


  const [SGSTTaxT1, setSGSTTaxt1] = useState(0);
  const [SGSTTaxT2, setSGSTTaxt2] = useState(0);
  const [SGSTTaxT3, setSGSTTaxt3] = useState(0);
  const [SGSTTaxT4, setSGSTTaxt4] = useState(0);
  const [SGSTTaxT5, setSGSTTaxt5] = useState(0);




  useEffect(() => {
    // if (data && data.details) {
    //   const subtotal = data.details.reduce(
    //     (total, item) =>
    //       total +
    //       (item.product_proprice
    //         ? item.product_proprice * item.quantity
    //         : item.product_price * item.quantity),
    //     0
    //   );

    //   setSubTotal(parseFloat(subtotal).toFixed(2));

    //   let discountedAmount = 0;
    //   let discount = 0;
    //   let deliveryCharge = 0;
    //   let packagingFee = 0;

    //   if (data.discount_type === "percent") {
    //     discount =
    //       (parseFloat(subtotal).toFixed(2) * (discountRate || 0)) / 100;
    //     discountedAmount = parseFloat(subtotal).toFixed(2) - discount;
    //   } else if (data.discount_type === "amount") {
    //     discount = parseFloat(discountRate) || 0;
    //     discountedAmount = parseFloat(subtotal).toFixed(2) - discount;
    //   }

    //   const sch = (discountedAmount * (SCHRate || 0)) / 100;
    //   const taxableAmount = discountedAmount + sch;

    //   const cgst = (taxableAmount * (CGSTRate || 0)) / 100;
    //   const sgst = (taxableAmount * (SGSTRate || 0)) / 100;

    //   if (
    //     data &&
    //     data.delivery_charge &&
    //     parseFloat(data.delivery_charge) > 0
    //   ) {
    //     deliveryCharge = parseFloat(data.delivery_charge).toFixed(2);
    //   }

    //   if (data && data.packaging_fee && parseFloat(data.packaging_fee) > 0) {
    //     packagingFee = parseFloat(data.packaging_fee).toFixed(2);
    //   }

    //   const totalPaid =
    //     parseFloat(taxableAmount) +
    //     parseFloat(cgst) +
    //     parseFloat(sgst) +
    //     parseFloat(deliveryCharge) +
    //     parseFloat(packagingFee);






    //   // let totalTaxAmount = 0;

    //   // if (data.tax_details && data.tax_details.length > 0) {
    //   //   // Calculate tax amount based on percentages and update tax_details
    //   //   const updatedTaxDetails = data.tax_details.map((tax) => {
    //   //     const taxPercentage = parseFloat(tax.percentage);
    //   //     const taxAmount = (taxableAmount * taxPercentage) / 100;
    //   //     totalTaxAmount += taxAmount;
    //   //     return {
    //   //       ...tax,
    //   //       amount: taxAmount.toFixed(2),
    //   //     };
    //   //   });

    //   //   setTaxDetails(updatedTaxDetails);
    //   // }

    //   // // Add total tax amount to the taxable amount
    //   // const totalPaid = taxableAmount + totalTaxAmount;

    //   setDiscountAmount(parseFloat(discount).toFixed(2));
    //   setSchAmount(parseFloat(sch).toFixed(2));
    //   setCGSTAmount(parseFloat(cgst).toFixed(2));
    //   setSGSTAmount(parseFloat(sgst).toFixed(2));
    //   setTotalAmountPaid(parseFloat(totalPaid).toFixed(2));
    // }

    if (data.details) {
      let withOutTaxPrice = 0
      let subTotal = 0;
      let tempDiscount = 0;
      let tempDiscountRow = 0;


      let tempServicCharge = 0;
      let tempServicChargeRow = 0;

      let tempTax = 0;
      let tempTaxRow = 0;

      let GSTTaxT1 = 0;
      let GSTTaxT2 = 0;
      let GSTTaxT3 = 0;
      let GSTTaxT4 = 0;
      let GSTTaxT5 = 0;

      let packingCharges = 0;


      let withOutTaxPriceForAmount = 0
      let subTotalForAmount = 0;
      let discountRateForAmount = 0;

      if (data.discount_type === "amount") {
        data.details.forEach((i) => {
          if (i.product_proprice) {
            withOutTaxPriceForAmount = parseInt(i.item_tax_type) === 0 ? parseFloat(i.product_proprice) : (parseFloat(i.product_proprice) * parseFloat(100)) / (parseFloat(100) + parseFloat(i.item_tax_percent))
          } else {
            withOutTaxPriceForAmount = parseInt(i.item_tax_type) === 0 ? parseFloat(i.product_price) : (parseFloat(i.product_price) * parseFloat(100)) / (parseFloat(100) + parseFloat(i.item_tax_percent))
          }
          subTotalForAmount += (i.quantity) * withOutTaxPriceForAmount
        })
        discountRateForAmount = (parseFloat(data.discount_rate) * 100) / subTotalForAmount
      }


      data.details.forEach((i) => {


        // newPrice: selectedProduct.proprice
        // ? selectedProduct.proprice
        // : selectedProduct.price,

        packingCharges += i.quantity * parseFloat(i.packaging_fee)

        if (i.product_proprice) {
          withOutTaxPrice = parseInt(i.item_tax_type) === 0 ? parseFloat(i.product_proprice) : (parseFloat(i.product_proprice) * parseFloat(100)) / (parseFloat(100) + parseFloat(i.item_tax_percent))

        } else {
          withOutTaxPrice = parseInt(i.item_tax_type) === 0 ? parseFloat(i.product_price) : (parseFloat(i.product_price) * parseFloat(100)) / (parseFloat(100) + parseFloat(i.item_tax_percent))
        }


        subTotal += (i.quantity) * withOutTaxPrice

        if (data.discount_type === "percent") {
          tempDiscount += (((i.quantity) * withOutTaxPrice) * parseFloat(data.discount_rate)) / 100
          tempDiscountRow = (((i.quantity) * withOutTaxPrice) * parseFloat(data.discount_rate)) / 100
        } else {
          // tempDiscount += (((i.quantity) * withOutTaxPrice) - parseFloat(data.discount_rate))
          // tempDiscountRow = (((i.quantity) * withOutTaxPrice) - parseFloat(data.discount_rate))

          // tempDiscount += ((i.quantity * withOutTaxPrice) - ((i.quantity * withOutTaxPrice) - parseFloat(data.discount_rate))) * i.quantity
          // tempDiscountRow = ((i.quantity * withOutTaxPrice) - ((i.quantity * withOutTaxPrice) - parseFloat(data.discount_rate))) * i.quantity

          tempDiscount += (((i.quantity) * withOutTaxPrice) * parseFloat(discountRateForAmount)) / 100
          tempDiscountRow = (((i.quantity) * withOutTaxPrice) * parseFloat(discountRateForAmount)) / 100

        }


        tempServicCharge += ((((i.quantity) * withOutTaxPrice) - tempDiscountRow) * parseFloat(SCHRate)) / 100
        tempServicChargeRow = ((((i.quantity) * withOutTaxPrice) - tempDiscountRow) * parseFloat(SCHRate)) / 100



        tempTax += ((((i.quantity) * withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.item_tax_percent)) / 100
        tempTaxRow = ((((i.quantity) * withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.item_tax_percent)) / 100

        if (parseInt(i.item_tax_percent) === 0) {

          GSTTaxT1 += ((((i.quantity) * withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.item_tax_percent)) / 100
          setCGSTTaxt1(GSTTaxT1 / 2)
          setSGSTTaxt1(GSTTaxT1 / 2)

        }

        if (parseInt(i.item_tax_percent) === 5) {

          GSTTaxT2 += ((((i.quantity) * withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.item_tax_percent)) / 100

          setCGSTTaxt2((GSTTaxT2 / 2).toFixed(2))
          setSGSTTaxt2((GSTTaxT2 / 2).toFixed(2))
        }

        if (parseInt(i.item_tax_percent) === 12) {

          GSTTaxT3 += ((((i.quantity) * withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.item_tax_percent)) / 100

          setCGSTTaxt3((GSTTaxT3 / 2).toFixed(2))
          setSGSTTaxt3((GSTTaxT3 / 2).toFixed(2))
        }

        if (parseInt(i.item_tax_percent) === 18) {

          GSTTaxT4 += ((((i.quantity) * withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.item_tax_percent)) / 100
          setCGSTTaxt4((GSTTaxT4 / 2).toFixed(2))
          setSGSTTaxt4((GSTTaxT4 / 2).toFixed(2))
        }

        if (parseInt(i.item_tax_percent) === 28) {

          GSTTaxT5 += ((((i.quantity) * withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.item_tax_percent)) / 100
          setCGSTTaxt5((GSTTaxT5 / 2).toFixed(2))
          setSGSTTaxt5((GSTTaxT5 / 2).toFixed(2))
        }

      })
      setSubTotal(subTotal.toFixed(2))
      setDiscountAmount(tempDiscount.toFixed(2))
      setSchAmount(tempServicCharge.toFixed(2))
      setTotalAmountPaid((subTotal + tempServicCharge - tempDiscount + tempTax + data.round_up_amount).toFixed(2))
      setTotalAmountWithoutRoundOff((subTotal + tempServicCharge - tempDiscount + tempTax))


      if (data.order_type !== "dine_in") {
        // setCartProductPackingCharges(packingCharges)
        setCartProductPackingCharges(packingCharges.toFixed(2))
        setTotalAmountPaid((parseFloat(subTotal + tempServicCharge - tempDiscount + tempTax + data.round_up_amount) + parseFloat(packingCharges)).toFixed(2))
        setTotalAmountWithoutRoundOff((subTotal + tempServicCharge - tempDiscount + tempTax))

      }
    }

  }, [data, discountRate, SCHRate, CGSTRate, SGSTRate]);

  useEffect(() => {
    if (printKOTDetails2) {
      const handlePrintKOT = async () => {
        if (printKOTDetails2) {
          printJS({
            printable: "contentToPrintKOT2",
            type: "html",
            targetStyles: ["*"],
          });

          setPrintKOTDetails2(null);
        }
      };
      handlePrintKOT();
    }
  }, [printKOTDetails2]);

  useEffect(() => {
    console.log(printBillDetails2)
    if (printBillDetails2) {

      const handlePrintBill2 = async () => {
        if (printBillDetails2) {
          printJS({
            printable: "contentToPrintBill2",
            type: "html",
            targetStyles: ["*"],
            font_size: '8pt'
          });

          setPrintBillDetails2(null);
        }
      };
      handlePrintBill2();
    }

  }, [printBillDetails2]);


  const [partialPaymentDetailModel, setPartialPaymentDetailModel] = useState(false);
  const [partialPaymentDetailTotalAmount, setPartialPaymentDetailTotalAmount] = useState(0);

  const [partialPaymentModel, setPartialPaymentlModel] = useState(false);


  const [selectedPaymentOption, setSelectedPaymentOption] = useState("Cash");


  const [onlySavePaymentPaidedAmount, setOnlySavePaymentPaidedAmount] = useState(0);
  const [onlySavePaymentTotalPaidedAmount, setOnlySavePaymentTotalPaidedAmount] = useState(0);


  const [onlySavePaymentTipAmount, setOnlySavePaymentTipAmount] = useState(0);
  const [onlySavePaymentTipTotalAmount, setOnlySavePaymentTipTotalAmount] = useState(0);

  const [onlySavePaymentNotes, setOnlySavePaymentNotes] = useState("");


  const [onlySavePaymentUPIMode, setOnlySavePaymentUPIMode] = useState("");
  const [onlySavePaymentUPIModeOther, setOnlySavePaymentUPIOther] = useState("");



  const [paymentSettleTransactionId, setPaymentSettleTransactionId] = useState("")

  const [onlySavePaymentData, setSavePaymentData] = useState([]);

  const [onlySavePaymentRoundUpAmount, setOnlySavePaymentRoundUpAmount] = useState(0);
  const [onlySavePaymentRoundUpCheck, setOnlySavePaymentRoundUpCheck] = useState(false);

  const handlePaymentOptionChange = (event) => {

    setSelectedPaymentOption(event.target.value);

    setOnlySavePaymentUPIOther("")
    setOnlySavePaymentUPIMode("")

    // setUnpaidReason("");
    // setPaymentSettleTransactionId("");
  };
  const [submitpartialPaymentButtonDisable, setSubmitpartialPaymentButtonDisable] = useState(false)


  const submitpartialPayment = async (type) => {

    const modifiedDetails = data.details.map((item) => {
      const { id, product_name, ...rest } = item;
      return {
        product_id: id,
        product_name: product_name,
      };
    });

    const dataToSend = {

      id: orderID,
      payment_details: onlySavePaymentData,
      due_amount: parseFloat(totalAmountPaid - onlySavePaymentTotalPaidedAmount).toFixed(2),
      paided_amount: parseFloat(onlySavePaymentTotalPaidedAmount).toFixed(2),
      total: parseFloat(totalAmountPaid).toFixed(2),
      // payment_status: parseFloat(totalAmountPaid - onlySavePaymentTotalPaidedAmount).toFixed(2) > 0 ? 3 : 1
      payment_status: parseFloat(totalAmountPaid - onlySavePaymentTotalPaidedAmount + onlySavePaymentRoundUpAmount).toFixed(2) > 0 ? 3 : 1,
      round_up_amount: onlySavePaymentRoundUpAmount,
      item: modifiedDetails

    };
    setSubmitpartialPaymentButtonDisable(true)

    try {

      const response = await axios({
        method: "put",
        url: LiveOrderNewPaymentURL,
        data: dataToSend,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setFlag(!flag);
        resetPaymentDetails()

        addToast("success", response.data.message);
        setPartialPaymentlModel(false)

        // setOnlySavePaymentRoundUpCheck(false)
        // setOnlySavePaymentRoundUpAmount(0)

      }
      setSubmitpartialPaymentButtonDisable(false)
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      setSubmitpartialPaymentButtonDisable(false)

      console.log(error);
    }
  };



  const isValidNumber = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };


  useEffect(() => {
    if (onlySavePaymentRoundUpCheck) {
    } else {
      setOnlySavePaymentRoundUpAmount(0)
      setOnlySavePaymentPaidedAmount(parseFloat(totalAmountPaid - onlySavePaymentTotalPaidedAmount).toFixed(2))
    }
  }, [onlySavePaymentRoundUpCheck]);

  // const handleRoundupAmountCheck = async (e) => {
  //   // alert(totalAmountPaid)
  //   var res = getDecimalPart(totalAmountPaid)
  //   // alert(res)
  //   console.log(e.target.checked)
  //   if (e.target.checked) {
  //     // setOnlySavePaymentRoundUpFixAmount(res)
  //     setOnlySavePaymentRoundUpAmount(res)
  //     setOnlySavePaymentPaidedAmount(parseFloat(totalAmountPaid) + res)
  //     setOnlySavePaymentRoundUpCheck(true)

  //   } else {

  //     setOnlySavePaymentRoundUpCheck(false)
  //   }

  // }

  function getDecimalPart(number) {
    // Extract the integer part using Math.floor() and subtract it from the original number
    const integerPart = Math.floor(number);
    let decimalPart = number - integerPart;
    // decimalPart = decimalPart.toFixed(2)
    if (decimalPart < 0.5) {
      return -decimalPart
    }
    else {
      return 1 - decimalPart
    }
  }


  const handleOnlySavePayment = async (e) => {

    let tempOnlySavePaymentRoundUpAmount = onlySavePaymentRoundUpAmount

    if (selectedPaymentOption === "UPI" && onlySavePaymentUPIMode === '') {
      addToast("error", "Select upi method");
    }
    else if (!isValidNumber(onlySavePaymentPaidedAmount)) {
      addToast("error", "Please enter valid Paid amount");
    }
    else if (!isValidNumber(onlySavePaymentTipAmount) && onlySavePaymentTipAmount !== '') {
      addToast("error", "Please enter valid Tip amount");
    }

    // else if (parseFloat(onlySavePaymentPaidedAmount).toFixed(2) > parseFloat(totalAmountPaid - onlySavePaymentTotalPaidedAmount) + 0.00001) {
    //   addToast("error", "Amount cannot be greater than Total Amount");
    // }

    else if ((parseFloat(onlySavePaymentPaidedAmount)).toFixed(2) > parseFloat(totalAmountPaid - onlySavePaymentTotalPaidedAmount) + (tempOnlySavePaymentRoundUpAmount) + 0.00001) {
      addToast("error", "Amount cannot be greater than Total Amount");
    }

    else {
      setSavePaymentData([...onlySavePaymentData, {
        method: selectedPaymentOption,
        amount: parseFloat(onlySavePaymentPaidedAmount).toFixed(2),
        tip: onlySavePaymentTipAmount ? parseFloat(onlySavePaymentTipAmount).toFixed(2) : 0,
        upiType: onlySavePaymentUPIModeOther === "Other" ? onlySavePaymentUPIModeOther : onlySavePaymentUPIMode,
        note: onlySavePaymentNotes,
        transaction_id: paymentSettleTransactionId
      }])
      let temp = parseFloat(onlySavePaymentTotalPaidedAmount) + parseFloat(onlySavePaymentPaidedAmount)
      setOnlySavePaymentTotalPaidedAmount(temp)

      // setOnlySavePaymentPaidedAmount(totalAmountPaid - temp > 0 ? (totalAmountPaid - temp).value.toFixed(2) : 0)
      // setOnlySavePaymentPaidedAmount(totalAmountPaid - temp > 0 ? parseFloat(totalAmountPaid - temp).toFixed(2) : 0)
      setOnlySavePaymentPaidedAmount(totalAmountPaid - temp > 0 ? parseFloat((totalAmountPaid - temp) + tempOnlySavePaymentRoundUpAmount).toFixed(2) : 0)


      let tempTip = onlySavePaymentTipAmount + onlySavePaymentTipTotalAmount
      setOnlySavePaymentTipTotalAmount(tempTip)
      setOnlySavePaymentTipAmount(0)
      setOnlySavePaymentNotes("")
      setPaymentSettleTransactionId("")
      setOnlySavePaymentUPIOther("")
      setOnlySavePaymentUPIMode("")
    }




    // console.log(onlySavePaymentData)


  }
  const handleOnlySavePaymentRemove = async (i) => {

    let tempArray = onlySavePaymentData.filter((item, index) => index === i);
    // console.log(tempArray)

    // setOnlySavePaymentPaidedAmount((parseFloat(totalAmountPaid) - parseFloat(onlySavePaymentTotalPaidedAmount) + parseFloat(tempArray[0].amount)).toFixed(2))
    setOnlySavePaymentPaidedAmount((parseFloat(totalAmountPaid) - parseFloat(onlySavePaymentTotalPaidedAmount) + parseFloat(tempArray[0].amount) + onlySavePaymentRoundUpAmount).toFixed(2))
    let temp = parseFloat(onlySavePaymentTotalPaidedAmount) - parseFloat(tempArray[0].amount)
    setOnlySavePaymentTotalPaidedAmount(temp)
    let myArray = onlySavePaymentData.filter((item, index) => index !== i);
    setSavePaymentData(myArray)

  }


  useEffect(() => {
    // setOnlySavePaymentPaidedAmount(totalAmountPaid - onlySavePaymentTotalPaidedAmount)
    // setOnlySavePaymentPaidedAmount(totalAmountPaid)
    // setOnlySavePaymentPaidedAmount((parseFloat(totalAmountPaid) + parseFloat(getDecimalPart(totalAmountPaid))).toFixed(2))

    if (onlySavePaymentRoundUpCheck && (data.payment_status === 0 || data.payment_status === 2)) {
      setOnlySavePaymentPaidedAmount((parseFloat(totalAmountPaid) + parseFloat(getDecimalPart(totalAmountPaid))).toFixed(2))
    } else {
      setOnlySavePaymentPaidedAmount(totalAmountPaid)
    }
    setSavePaymentData([]);
    setOnlySavePaymentTotalPaidedAmount(0)
    setOnlySavePaymentTipAmount(0)
    setOnlySavePaymentNotes("")
    setOnlySavePaymentTipTotalAmount(0)
    setOnlySavePaymentUPIOther("")
    setOnlySavePaymentUPIMode("")
    if (onlySavePaymentRoundUpCheck && (data.payment_status === 0 || data.payment_status === 2)) {
      setOnlySavePaymentRoundUpAmount(getDecimalPart(totalAmountPaid))
    }

  }, [totalAmountPaid]);

  const [showDuePaymentMode, setShowDuePaymentMode] = useState(false);
  const [disabledDuePaymentButton, setDisabledDuePaymentButton] = useState(false);


  const submitDuePayment = async () => {
    const modifiedDetails = data.details.map((item) => {
      const { id, product_name, ...rest } = item;
      return {
        product_id: id,
        product_name: product_name,
      };
    });

    const dataToSend = {

      id: orderID,
      payment_details: {
        method: selectedPaymentOption,
        amount: parseFloat(totalAmountPaid - partialPaymentDetailTotalAmount).toFixed(2),
        upiType: onlySavePaymentUPIModeOther === "Other" ? onlySavePaymentUPIModeOther : onlySavePaymentUPIMode,
        note: "",
        transaction_id: ""
      },
      due_amount: parseFloat(totalAmountPaid - partialPaymentDetailTotalAmount).toFixed(2),
      total: parseFloat(totalAmountPaid).toFixed(2),
      payment_status: 1,
      item: modifiedDetails


    };


    try {

      setDisabledDuePaymentButton(true)

      const response = await axios({
        method: "put",
        url: LiveOrderDuePaymentSubmitURl,
        data: dataToSend,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        resetPaymentDetails()
        setFlag(!flag);

        addToast("success", response.data.message);
        setPartialPaymentlModel(false)

      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
  };

  const resetPaymentDetails = () => {
    setPartialPaymentDetailModel(false)
    setPartialPaymentlModel(false)
    setSelectedPaymentOption("Cash")
    setOnlySavePaymentPaidedAmount(0)
    setOnlySavePaymentTotalPaidedAmount(0)
    setOnlySavePaymentTipAmount(0)
    setOnlySavePaymentTipTotalAmount(0)
    setOnlySavePaymentNotes("")
    setOnlySavePaymentUPIMode("")
    setOnlySavePaymentUPIOther("")
    setPaymentSettleTransactionId("")
    setSavePaymentData([])
  }

  const [paymentDetailsData, setPaymentDetailsData] = useState([]);
  const [paymentDetailsUpdateButton, setPaymentDetailsUpdateButton] = useState(false);



  // useEffect(() => {
  //   console.log(paymentDetailsData)
  // }, [paymentDetailsData]);




  const handlePaymentMethodChange = async (value, id) => {
    console.log(value, id);

    const updatedPaymentDetails = paymentDetailsData.map(item =>
      item.id === id ? { ...item, paymentType: value } : item
    );
    setPaymentDetailsUpdateButton(true)
    setPaymentDetailsData(updatedPaymentDetails);
  };

  const updatePaymentMethod = async () => {
    console.log("update payment")



    try {
      const response = await axios({
        method: "put",
        url: paymentMethodChangeURl,
        data: { payment_details: paymentDetailsData },
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200 && response.data.message) {
        addToast("success", response.data.message);
        setPaymentDetailsUpdateButton(false)
        setPartialPaymentDetailModel(false)


        setFlag(!flag)
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }

  };


  const [isOpenSpiltBill, setIsOpenSpiltBill] = useState(false);
  const [customerDataForSpiltBill, setCustomerDataForSpiltBill] = useState({
    name: "",
    address: "",
    mobile: "",
  });

  const [paymentDataForSpiltBill, setPaymentDataForSpiltBill] = useState({
    method: "Cash",
    amount: 0,
    upiType: "",
    note: "",
    transaction_id: "",
    tip: 0
  });

  const handleCustomerFormChangeForSplitBill = (e) => {
    const { name, value } = e.target;
    setCustomerDataForSpiltBill({
      ...customerDataForSpiltBill,
      [name]: value,
    });
  };












  const [splitBillNumber, setSplitBillNumber] = useState(1);
  const [splitBillData, setSplitBillData] = useState([]);
  const [splitBillCreationData, setSplitBillCreationData] = useState([]);
  const [splitBillCreationAmountData, setSplitBillCreationAmountData] = useState({
    total: 0,
    subTotal: 0,
    discountAmount: 0,
    schAmount: 0,
    taxAmount: 0,
    packingCharges: 0,
  });
  const addItemsForSplitBillCreation = async (item) => {

    const billDataTemp = splitBillData;
    let tempQuantity = 0
    billDataTemp.forEach(bill => {
      bill.items_details.forEach(i => {
        if (i.id === item.id) {
          tempQuantity = tempQuantity + i.quantity
        }
      });
    });


    let temp_data = {
      id: item.id,
      order_id: item.order_id,
      fooder_id: item.fooder_id,
      product_kot_id: item.product_kot_id,
      phone: item.phone,
      table_id: item.table_id,
      menu_id: item.menu_id,
      product_id: item.product_id,
      product_type: item.product_type,
      product_name: item.product_name,
      quantity: item.quantity - tempQuantity,
      max_quantity: item.quantity,
      product_price: item.product_price,
      product_special_note: item.product_special_note,
      addon_item: item.addon_item,
      variant_id: item.variant_id,
      variant_details: item.variant_details,
      addons_items_details: item.addons_items_details,
      packaging_fee: item.packaging_fee,
      item_kot_status: item.item_kot_status,
      local_time: item.local_time,
      item_tax_type: item.item_tax_type,
      item_tax_percent: item.item_tax_percent,
    }

    setSplitBillCreationData([...splitBillCreationData, temp_data]);

    const updatedBillDataTemp = splitBillData;
    updatedBillDataTemp.forEach(bill => {
      if (bill.bill_number === splitBillNumber) {
        // Push the item into items_details without reassigning
        bill.items_details.push(temp_data);
        // Optionally, update splitBillNumber based on the updated length if needed
      }
    });
    setSplitBillData(updatedBillDataTemp);




  }

  const removeItemsForSplitBillCreation = async (idToRemove) => {

    const updatedItems = splitBillCreationData.filter(item => item.id !== idToRemove);
    setSplitBillCreationData(updatedItems)

    const updatedBillDataTemp = splitBillData;
    updatedBillDataTemp.forEach(bill => {
      if (bill.bill_number === splitBillNumber) {
        // Filter out the item with the specified ID
        const items_details = bill.items_details.filter(item => item.id !== idToRemove);
        bill.items_details = items_details;
        // Set splitBillNumber based on the updated length
        if (items_details.length === 0)
          setSplitBillNumber(updatedBillDataTemp.length);
      }
    });
    const updatedBillData = updatedBillDataTemp.filter(bill => bill.items_details.length !== 0)
    let tempBillNo = 1; updatedBillData.forEach(bill => { bill.bill_number = tempBillNo++; });
    setSplitBillData(updatedBillData)

  }

  const updateQuantityItemsForSplitBillCreation = async (id, quantity) => {


    const billDataTemp = splitBillData;
    let tempExistQuantity = 0
    billDataTemp.forEach(bill => {
      if (bill.bill_number !== splitBillNumber) {
        bill.items_details.forEach(i => {
          if (i.id === id) {
            tempExistQuantity = tempExistQuantity + i.quantity
          }
        });
      }
    });

    const splitBillCreationDataTemp = [...splitBillCreationData];
    splitBillCreationDataTemp.forEach(item => {
      if (item.id === id) {
        if (item.quantity + quantity >= 1 && item.quantity + quantity <= item.max_quantity && ((item.quantity + quantity) + tempExistQuantity) <= item.max_quantity) {
          item.quantity = item.quantity + quantity
        } else {
          if (item.quantity + quantity < 1) {
            addToast("error", "Quantity cannot be zero.")
          }
          else {
            addToast("error", "Max Quantity Selected")
          }

        }
      }
    });
    setSplitBillCreationData(splitBillCreationDataTemp)

  }


  useEffect(() => {
    if (splitBillCreationData) {

      let withOutTaxPrice = 0
      let subTotal = 0;
      let tempDiscount = 0;
      let tempDiscountRow = 0;
      let tempServicCharge = 0;
      let tempServicChargeRow = 0;
      let tempTax = 0;
      let tempTaxRow = 0;
      let packingCharges = 0;
      let withOutTaxPriceForAmount = 0
      let subTotalForAmount = 0;
      let discountRateForAmount = 0;
      let tempTaxDetails = [];

      if (data.discount_type === "amount") {
        splitBillCreationData.forEach((i) => {
          withOutTaxPriceForAmount = parseInt(i.item_tax_type) === 0 ? parseFloat(i.product_price) : (parseFloat(i.product_price) * parseFloat(100)) / (parseFloat(100) + parseFloat(i.item_tax_percent))
          subTotalForAmount += (i.quantity) * withOutTaxPriceForAmount
        })
        discountRateForAmount = (parseFloat(data.discount_rate) * 100) / subTotalForAmount
      }

      splitBillCreationData.forEach((i) => {
        packingCharges += i.quantity * parseFloat(i.packaging_fee)
        withOutTaxPrice = parseInt(i.item_tax_type) === 0 ? parseFloat(i.product_price) : (parseFloat(i.product_price) * parseFloat(100)) / (parseFloat(100) + parseFloat(i.item_tax_percent))
        subTotal += (i.quantity) * withOutTaxPrice
        console.log(subTotal)
        console.log("subTotal")

        if (data.discount_type === "percent") {
          tempDiscount += (((i.quantity) * withOutTaxPrice) * parseFloat(data.discount_rate)) / 100
          tempDiscountRow = (((i.quantity) * withOutTaxPrice) * parseFloat(data.discount_rate)) / 100
        } else {
          tempDiscount += (((i.quantity) * withOutTaxPrice) * parseFloat(discountRateForAmount)) / 100
          tempDiscountRow = (((i.quantity) * withOutTaxPrice) * parseFloat(discountRateForAmount)) / 100
        }


        tempServicCharge += ((((i.quantity) * withOutTaxPrice) - tempDiscountRow) * parseFloat(SCHRate)) / 100
        tempServicChargeRow = ((((i.quantity) * withOutTaxPrice) - tempDiscountRow) * parseFloat(SCHRate)) / 100

        tempTax += ((((i.quantity) * withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.item_tax_percent)) / 100
        tempTaxRow = ((((i.quantity) * withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.item_tax_percent)) / 100

        let taxFinder = false
        tempTaxDetails.forEach((tax) => {
          if (tax.percentage === i.item_tax_percent / 2) {
            tax.amount += (tempTaxRow / 2)
            taxFinder = true
          }
        });
        if (!taxFinder && i.item_tax_percent > 0) {
          tempTaxDetails.push({ "name": "CGST", "percentage": i.item_tax_percent / 2, "amount": tempTaxRow / 2 })
          tempTaxDetails.push({ "name": "SGST", "percentage": i.item_tax_percent / 2, "amount": tempTaxRow / 2 })
        }
      })

      let tempAmountData = {
        total: subTotal + tempServicCharge - tempDiscount + tempTax,
        subTotal: subTotal,
        discountDetails: { discountType: data.discount_type === "amount" ? 1 : 0, discountRate: data.discount_rate, discountAmount: tempDiscount },
        schDetails: { name: data.service_charge_details?.name, percentage: SCHRate, amount: tempServicCharge },
        schAmount: tempServicCharge,
        taxAmount: tempTax,
        taxDetails: tempTaxDetails,
        packingCharges: 0,
      }

      const updatedBillDataTemp = splitBillData;
      updatedBillDataTemp.forEach(bill => {
        if (bill.bill_number === splitBillNumber) {
          // Push the item into items_details without reassigning
          bill.amount_details = tempAmountData;
          bill.payment_details = { ...paymentDataForSpiltBill, ["amount"]: subTotal + tempServicCharge - tempDiscount + tempTax };
          // Optionally, update splitBillNumber based on the updated length if needed
        }
      });
      setSplitBillData(updatedBillDataTemp);
      setSplitBillCreationAmountData(tempAmountData);
      setPaymentDataForSpiltBill({ ...paymentDataForSpiltBill, ["amount"]: subTotal + tempServicCharge - tempDiscount + tempTax })
    }
  }, [splitBillCreationData]);


  const createNewSplitBill = async () => {
    const mobileNumberPattern = /^\d{10}$/;

    if (splitBillCreationData.length <= 0) {
      addToast("error", "Please add items...");

    }
    else if (!mobileNumberPattern.test(customerDataForSpiltBill.mobile) && customerDataForSpiltBill.mobile !== '') {
      addToast("error", "Please Enter valid Mobile Number");
    }
    else if (!isValidNumber(paymentDataForSpiltBill.tip) && paymentDataForSpiltBill.tip !== '') {
      addToast("error", "Please Enter valid Tip Amount");
    }

    else {


      let finder = false

      const updatedBillDataTemp = splitBillData;
      updatedBillDataTemp.forEach(bill => {
        if (bill.bill_number === splitBillNumber) {
          // Push the item into items_details without reassigning
          bill.amount_details = splitBillCreationAmountData;
          bill.payment_details = paymentDataForSpiltBill;
          bill.customer_details = customerDataForSpiltBill
          bill.items_details = splitBillCreationData
          setSplitBillNumber(splitBillData.length + 1)
          finder = true

          // Optionally, update splitBillNumber based on the updated length if needed
        }
      });
      setSplitBillData(updatedBillDataTemp);

      if (!finder) {
        let temp_data = {}
        temp_data.items_details = splitBillCreationData
        temp_data.customer_details = customerDataForSpiltBill
        temp_data.payment_details = paymentDataForSpiltBill
        temp_data.amount_details = splitBillCreationAmountData
        temp_data.bill_number = splitBillNumber

        // console.log(splitBillData)

        setSplitBillData([...splitBillData, temp_data]);
        setSplitBillNumber(splitBillNumber + 1)

      }
      setSplitBillCreationData([])
      setCustomerDataForSpiltBill({ name: "", address: "", mobile: "", })
      setPaymentDataForSpiltBill({ method: "Cash", amount: 0, upiType: "", note: "", transaction_id: "", tip: 0 })



    }
  }

  const saveSplitBills = async () => {
    console.log(splitBillData)
    if (splitBillData.length <= 0) {
      addToast("error", "Please add bills...");

    } else {


      const billDataTemp = splitBillData;
      let totalSplitAmount = 0
      billDataTemp.forEach(bill => {



        totalSplitAmount = totalSplitAmount + bill.amount_details.total


      });
      setIsLoading(true);

      try {
        const response = await axios({
          method: "post",
          url: orderSplitBillCreationURL,
          data: {
            order_id: orderID,
            bill_data: splitBillData,
            orderTotal: totalAmountWithoutRoundOff,
            payment_status: totalSplitAmount >= totalAmountWithoutRoundOff ? 1 : 3
          },
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          addToast("success", response.data.message);
          setIsOpenSpiltBill(false)
          setFlag(!flag);

        }else{
          if(response.data.message){
          addToast("error", response.data.message);
          setFlag(!flag);

          }

        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        setFlag(!flag);
        console.log(error);
      }
    }
  }

  const chickExistsProductSplitBillCreation = (idToCheck) => {
    const exists = splitBillCreationData.some((item) => item.id === idToCheck);
    return exists
  }

  const chickExistsProductSplitBill = (idToCheck, quantity) => {
    // if (splitBillData) {
    //   return splitBillData.some(order =>
    //     order.items_details.some(item => item.id === idToCheck)
    //   );
    // }
    // return false
    const updatedBillDataTemp = splitBillData;
    let tempQuantity = 0
    updatedBillDataTemp.forEach(bill => {
      bill.items_details.forEach(item => {
        if (item.id === idToCheck) {
          tempQuantity = tempQuantity + item.quantity
        }
      });
    });
    if (tempQuantity === quantity) {
      return true
    } else {
      return false
    }

  }

  const getCountExistsProductSplitBill = (idToCheck) => {
    const updatedBillDataTemp = splitBillData;
    let tempQuantity = 0
    updatedBillDataTemp.forEach(bill => {
      bill.items_details.forEach(item => {
        if (item.id === idToCheck) {
          tempQuantity = tempQuantity + item.quantity
        }
      });
    });
    return tempQuantity
  }


  const checkAllItemsInSplitBill = () => {
    if (!data || !data.details) return false;

    return data.details.every((item) =>
      chickExistsProductSplitBill(item.id, item.quantity)
    );
  };

  const clickOnExistsSplitBill = (number) => {
    const temp_data = splitBillData.filter(bill => bill.bill_number === number);
    setSplitBillNumber(temp_data[0].bill_number)
    setSplitBillCreationData(temp_data[0].items_details)
    setCustomerDataForSpiltBill(temp_data[0].customer_details)
    setPaymentDataForSpiltBill(temp_data[0].payment_details)
  }
  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="orders" toggleCanvas={canvas} />

              <div className="main-panel">
                <div
                  className={partialPaymentDetailModel ? "modal fade show d-block" : "modal fade"}
                  id="exampleModal"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content w-85">
                      <div className="modal-header py-2">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Payment Details (Order Id #{data && data.order_number_qrcode})
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => { setPartialPaymentDetailModel(false); resetPaymentDetails() }}
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body py-2">
                        <div className="container">


                        </div>

                      </div>

                      <div className="itemdiv mx-2">
                        <div className="row">
                          <div className="col-lg-2">
                            <p className="itemnm">No</p>
                          </div>
                          <div className="col-lg-5">
                            <p className="itemnm">Payment Method</p>
                          </div>
                          <div className="col-lg-5">
                            <p className="itemnm">Amount</p>
                          </div>
                          {/* <div className="col-lg-4">
                            <p className="itemnm">Tip Amount</p>
                          </div> */}

                        </div>
                      </div>
                      {data.partialPaymentDetail &&

                        data.partialPaymentDetail.map((item, index) => (


                          <div key={index} className="itemdiv mx-2">
                            <div className="row">
                              <div className="col-lg-2">
                                <p className="itemnm">{index + 1}</p>
                              </div>
                              <div className="col-lg-5">

                                {/* <p className="itemnm">
                                  {item.paymentType === 'cash' && "Cash"}
                                  {item.paymentType === 'card' && "Card"}
                                  {item.paymentType === 'neft' && "NEFT"}
                                  {item.paymentType === 'upi' && "UPI"}
                                </p> */}

                                <select disabled={showDuePaymentMode && data.payment_status === 3} defaultValue={item.paymentType} onChange={(e) => { handlePaymentMethodChange(e.target.value, item.id) }} className="form-select" name="method">
                                  <option value="cash">Cash</option>
                                  <option value="card">Card</option>
                                  <option value="neft">NEFT</option>
                                  <option value="upi">UPI</option>
                                </select>

                                {/* <select className="form-select" name="method">
                                   <option value="Google Pay">Google Pay</option>
                                  <option value="PhonePe">PhonePe</option>
                                  <option value="Paytm">Paytm</option>
                                  <option value="Other">Other</option>
                                </select> */}

                              </div>
                              <div className="col-lg-5">
                                <p className="itemnm">₹{parseFloat(item.paidAmount).toFixed(2)}</p>

                              </div>
                              {/* <div className="col-lg-4">
                                <p className="itemnm">{item.tipAmount && 0}</p>
                              </div> */}

                            </div>
                          </div>
                        ))
                      }

                      {((data.partialPaymentDetail && data.partialPaymentDetail.length <= 0) || (data.partialPaymentDetail === undefined)) &&


                        <div className="itemdiv mx-2">
                          <div className="row">
                            <div className="col-lg-2">
                              <p className="itemnm">{1}</p>
                            </div>
                            <div className="col-lg-5">
                              <p className="itemnm">
                                {data.payment_type === 'cash' && "Cash"}
                                {data.payment_type === 'card' && "Card"}
                                {data.payment_type === 'neft' && "NEFT"}
                                {data.payment_type === 'upi' && "UPI"}
                              </p>



                            </div>
                            <div className="col-lg-5">
                              <p className="itemnm">₹{parseFloat(totalAmountPaid).toFixed(2)}</p>
                            </div>
                          </div>
                        </div>
                      }


                      <div className="modal-footer pymtftr" style={{ padding: "15px 0px" }}>
                        <div className="row" style={{ width: "100%" }}>
                          <div className="col-6 valign">
                            <div className="kr-partial-payment-detail-font-size">
                              <b> Total: {currencySymbol}{totalAmountPaid}</b>
                              <br />
                              {(data.partialPaymentDetail && data.partialPaymentDetail.length > 0) &&
                                <div>
                                  Paid: {currencySymbol}{partialPaymentDetailTotalAmount.toFixed(2)} &ensp;
                                  {parseFloat(totalAmountPaid - partialPaymentDetailTotalAmount).toFixed(2) >= 0 ? (
                                    <>Due: {currencySymbol}{parseFloat(Math.abs(parseFloat(totalAmountPaid - partialPaymentDetailTotalAmount))).toFixed(2)}</>
                                  ) : <>Return To: {currencySymbol}{Math.abs(parseFloat(totalAmountPaid - partialPaymentDetailTotalAmount).toFixed(2))}</>}

                                </div>
                              }
                              {/* {totalAmountPaid - partialPaymentDetailTotalAmount > 0 && `Due: ${totalAmountPaid - partialPaymentDetailTotalAmount}`} */}
                              {/* {data.payment_type === 'partial' && `Due: ${currencySymbol}${parseFloat(totalAmountPaid - partialPaymentDetailTotalAmount).toFixed(2)}`} */}
                            </div>
                          </div>
                          <div className="col-lg-6 text-right">
                            {data.payment_status === 3 && !paymentDetailsUpdateButton && (
                              showDuePaymentMode ? (
                                <button type="button" className="btn btn-success mx-1" disabled={disabledDuePaymentButton} onClick={submitDuePayment}>
                                  Submit
                                </button>
                              ) : (
                                <button type="button" className="btn btn-primary mx-1" onClick={() => setShowDuePaymentMode(true)}>
                                  Pay Due
                                </button>
                              )
                            )}
                            {
                              paymentDetailsUpdateButton && (
                                <>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-dismiss="modal"
                                    onClick={() => { updatePaymentMethod() }}
                                  >
                                    Update
                                  </button>{' '}

                                </>

                              )
                            }



                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-dismiss="modal"
                              onClick={() => { setPartialPaymentDetailModel(false); resetPaymentDetails() }}
                            >
                              Cancel
                            </button>

                            {/* setPaymentDetailsUpdateButton(true) */}

                          </div>
                        </div>

                        {showDuePaymentMode && data.payment_status === 3 &&
                          <div className="row" style={{ width: "100%" }}>
                            <div className="">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"
                                  value="Cash"
                                  checked={selectedPaymentOption === "Cash"}
                                  onChange={handlePaymentOptionChange}
                                />
                                <label className="form-check-label">Cash</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"

                                  value="Card"
                                  checked={selectedPaymentOption === "Card"}
                                  onChange={handlePaymentOptionChange}

                                />
                                <label className="form-check-label">Card</label>
                              </div>

                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"


                                  value="NEFT"
                                  checked={selectedPaymentOption === "NEFT"}
                                  onChange={handlePaymentOptionChange}
                                />
                                <label className="form-check-label">NEFT</label>
                              </div>


                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"
                                  value="UPI"
                                  checked={selectedPaymentOption === "UPI"}
                                  onChange={handlePaymentOptionChange}
                                />
                                <label className="form-check-label">UPI</label>
                              </div>

                            </div>
                            {selectedPaymentOption === 'UPI' && (

                              <div className="col-12  mt-3">
                                <label className="">
                                  Select Method
                                  <span className="text-danger">*</span>
                                </label>
                                <select
                                  className="form-select"
                                  name="method"
                                  value={onlySavePaymentUPIMode}
                                  onChange={(e) => { setOnlySavePaymentUPIMode(e.target.value) }}
                                >
                                  <option value="">Select Method</option>
                                  <option value="Google Pay">
                                    Google Pay
                                  </option>
                                  <option value="PhonePe">PhonePe</option>
                                  <option value="Paytm">Paytm</option>
                                  <option value="Other">Other</option>
                                </select>
                                {onlySavePaymentUPIMode === "Other" && (
                                  <>
                                    &nbsp;
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Method"
                                      name="other_method"
                                      value={
                                        onlySavePaymentUPIModeOther
                                      }
                                      onChange={
                                        (e) => { setOnlySavePaymentUPIOther(e.target.value) }
                                      }
                                    />
                                  </>
                                )}
                              </div>

                            )}


                          </div>
                        }
                      </div>

                    </div>
                  </div>
                </div>

                <div
                  className={partialPaymentModel ? "modal fade show d-block" : "modal fade"}
                  id="exampleModal"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content w-85">
                      <div className="modal-header py-2">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Bill Settlement Option (Order Id #{data && data.order_number_qrcode})
                        </h5>

                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          // onClick={() => { setPartialPaymentlModel(false); resetPaymentDetails() }}
                          onClick={() => { setPartialPaymentlModel(false); }}


                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body py-2">
                        <div className="container">
                          <form className="forms-sample">
                            <h4 className="card-title m-3 fw-bold ms-0">
                              Select Payment Option
                            </h4>
                            <h6 className="">
                              {/* Total Amount: ₹ {totalAmountPaid} */}
                              Total Amount: ₹ {(parseFloat(totalAmountPaid) + parseFloat(onlySavePaymentRoundUpAmount)).toFixed(2)}

                            </h6>
                            <div className="row">
                              <div className="">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    value="Cash"
                                    checked={selectedPaymentOption === "Cash"}
                                    onChange={handlePaymentOptionChange}
                                  />
                                  <label className="form-check-label">Cash</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"

                                    value="Card"
                                    checked={selectedPaymentOption === "Card"}
                                    onChange={handlePaymentOptionChange}

                                  />
                                  <label className="form-check-label">Card</label>
                                </div>

                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"


                                    value="NEFT"
                                    checked={selectedPaymentOption === "NEFT"}
                                    onChange={handlePaymentOptionChange}
                                  />
                                  <label className="form-check-label">NEFT</label>
                                </div>


                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    value="UPI"
                                    checked={selectedPaymentOption === "UPI"}
                                    onChange={handlePaymentOptionChange}
                                  />
                                  <label className="form-check-label">UPI</label>
                                </div>

                              </div>
                            </div>


                            <div className="row">

                              {selectedPaymentOption === 'UPI' && (

                                <div className="col-12  mt-3">
                                  <label className="">
                                    Select Method
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    className="form-select"
                                    name="method"
                                    value={onlySavePaymentUPIMode}
                                    onChange={(e) => { setOnlySavePaymentUPIMode(e.target.value) }}
                                  >
                                    <option value="">Select Method</option>
                                    <option value="Google Pay">
                                      Google Pay
                                    </option>
                                    <option value="PhonePe">PhonePe</option>
                                    <option value="Paytm">Paytm</option>
                                    <option value="Other">Other</option>
                                  </select>
                                  {onlySavePaymentUPIMode === "Other" && (
                                    <>
                                      &nbsp;
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Method"
                                        name="other_method"
                                        value={
                                          onlySavePaymentUPIModeOther
                                        }
                                        onChange={
                                          (e) => { setOnlySavePaymentUPIOther(e.target.value) }
                                        }
                                      />
                                    </>
                                  )}
                                </div>
                              )}








                              <div className="col-12  mt-3">
                                <label className="">
                                  Customer Paid<span className="text-danger">*</span>
                                </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Customer Paid"
                                    name="customerPaid"
                                    value={onlySavePaymentPaidedAmount}
                                    onChange={(e) => { setOnlySavePaymentPaidedAmount(e.target.value) }}
                                  />
                                  <span className="input-group-text">₹</span>
                                </div>
                              </div>

                              <div className="col-12  mt-3">
                                <label className="">Tip</label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Tip"
                                    name="tip"
                                    value={onlySavePaymentTipAmount}
                                    onChange={(e) => { setOnlySavePaymentTipAmount(e.target.value) }}
                                  />
                                  <span className="input-group-text">₹</span>
                                </div>
                              </div>


                              <div className="col-12  mt-3">
                                <label className="">Transaction / Reference ID</label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Transaction / Reference ID"
                                    name="transaction_id"
                                    value={paymentSettleTransactionId}
                                    onChange={(e) => { setPaymentSettleTransactionId(e.target.value) }}
                                  />
                                </div>
                              </div>



                            </div>

                            {selectedPaymentOption === 'Cash' ?

                              <div className="col-12  mt-3">
                                <label className="">
                                  {parseFloat(onlySavePaymentTotalPaidedAmount) + parseFloat(onlySavePaymentPaidedAmount) < parseFloat(totalAmountPaid)
                                    ? "Due"
                                    : "Return to Customer"}
                                </label>


                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Return to Customer"
                                    name="returnToCustomer"
                                    value={
                                      // Math.abs((totalAmountPaid - onlySavePaymentTotalPaidedAmount) - onlySavePaymentPaidedAmount)
                                      Math.abs(parseFloat((totalAmountPaid - onlySavePaymentTotalPaidedAmount) - onlySavePaymentPaidedAmount + onlySavePaymentRoundUpAmount).toFixed(2))
                                    }
                                    readOnly={true}
                                  />
                                  <span className="input-group-text">
                                    {currencySymbol}
                                  </span>
                                </div>
                              </div>

                              :
                              <div className="col-12  mt-3">
                                <label className="">Note</label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Note"
                                    name="note"


                                    value={onlySavePaymentNotes}
                                    onChange={(e) => { setOnlySavePaymentNotes(e.target.value) }}

                                  />
                                </div>
                              </div>

                            }





                          </form>

                          {selectedPaymentOption !== "Hold" && onlySavePaymentData.length > 0 && (

                            <button
                              className="btn btn-primary mt-1"
                              onClick={handleOnlySavePayment}
                              type="button"
                              disabled={
                                // onlySavePaymentPaidedAmount <= 0
                                totalAmountPaid - onlySavePaymentTotalPaidedAmount <= 0 || onlySavePaymentPaidedAmount <= 0

                              }
                            >
                              Add
                            </button>
                          )}



                          {onlySavePaymentData.length > 0 && (<>
                            <div className="itemdiv">
                              <div className="row">

                                <div className="col-lg-4">
                                  <p className="itemnm">Payment Method</p>
                                </div>

                                <div className="col-lg-3">
                                  <p className="itemnm">Amount</p>
                                </div>

                                <div className="col-lg-3">
                                  <p className="itemnm">Tip Amount</p>
                                </div>

                                <div className="col-lg-2">
                                  <p className="itemnm">Action</p>
                                </div>

                              </div>


                            </div>
                          </>)}



                          {onlySavePaymentData.map((item, index) => (
                            <div className="itemdiv" key={index}>

                              <div className="row">
                                <div className="col-lg-4">
                                  <p>{item.method === "UPI" ? item.method + " by " + item.upiType : item.method}</p>
                                </div>
                                <div className="col-lg-3">

                                  <p>{item.amount}</p>

                                </div>
                                <div className="col-lg-3">
                                  <p>{item.tip}</p>
                                </div>
                                <div className="col-lg-2">
                                  <span className="" style={{ cursor: "pointer" }} onClick={() => { handleOnlySavePaymentRemove(index) }}>
                                    <i className="fa fa-window-close closeicon" aria-hidden="true" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}





                        </div>

                      </div>

                      {/* <div className="itemdiv mx-2">
                        <div className="row">
                          <div className="col-lg-2">
                            <p className="itemnm">No</p>
                          </div>
                          <div className="col-lg-5">
                            <p className="itemnm">Payment Method</p>
                          </div>
                          <div className="col-lg-5">
                            <p className="itemnm">Amount</p>
                          </div>
                          

                        </div>
                      </div>
                       */}



                      <div className="modal-footer py-1">

                        {onlySavePaymentData.length > 0 ? <>
                          <button
                            className="btn btn-primary"
                            disabled={submitpartialPaymentButtonDisable}
                            onClick={submitpartialPayment}
                          >
                            Paid
                          </button>
                        </> : <>
                          {/* <div className="form-check form-check-inline mx-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="delivery"
                              defaultValue="delivery"
                              checked={onlySavePaymentRoundUpCheck}
                              onChange={handleRoundupAmountCheck}
                            />
                            <label className="form-check-label">Click here to Round Off amount.</label>
                          </div> */}
                          <button
                            className="btn btn-primary mx-1"
                            onClick={handleOnlySavePayment}
                            type="button"
                            disabled={
                              onlySavePaymentPaidedAmount <= 0
                            }
                          >
                            Save
                          </button>

                        </>}





                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={() => { setPartialPaymentlModel(false); }}
                        >
                          Cancel
                        </button>

                      </div>
                    </div>
                  </div>
                </div>



                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="col-sm-4">

                          <h3 className="ukhd mb-2" >
                            {data && data.invoice_number}
                          </h3>

                          <h3 className="ukhd mb-2">
                            Order Id #{data && data.order_number_qrcode}
                          </h3>
                          <h5 className="mb-2">#{data && data.order_number}</h5>
                          {/* <p>{data && data.order_type} | {data && data.table_no}</p> */}

                          {data.order_type == 'dine_in' ? <>

                            <p
                              style={{
                                display: "block",
                              }}
                            >

                              Dine In(
                              {data.table_no})
                            </p>

                          </> : <></>}

                          {data.order_type == 'take_away' ? <>
                            <p
                              style={{
                                display: "block",
                              }}
                            >
                              Take Away

                            </p>

                          </> : <></>}

                          {data.order_type == 'delivery' ? <>

                            <p
                              style={{
                                display: "block",
                              }}
                            >
                              Delivery

                            </p>




                          </> : <></>}
                          <p>Platform: {data && data.order_mode_label}</p>

                          {data.order_type == 'dine_in' ? <>

                            <p> {data && "No of Person Served:" + data.totalPerson}</p>

                          </> : <></>}

                        </div>
                        <div className="col-sm-4 text-center">
                          <h5 className="">
                            Status :
                            {data && data.status === 0 && (
                              <div className="badge badge-opacity-primary">
                                {data.status_lable}
                              </div>
                            )}
                            {data && data.status === 1 && (
                              <div className="badge badge badge-theme">
                                {data.status_lable}
                              </div>
                            )}
                            {data && data.status === 2 && (
                              <div className="badge badge-info">
                                {data.status_lable}
                              </div>
                            )}
                            {data && data.status === 3 && (
                              <div className="badge badge-opacity-warning">
                                {data.status_lable}
                              </div>
                            )}
                            {data && data.status === 4 && (
                              <div className="badge badge-danger">
                                {data.status_lable}
                              </div>
                            )}
                          </h5>
                          <p>
                            Placed on:
                            <span className="text-primary">
                              {data &&
                                data.creation_date &&
                                formatTimestamp(data.creation_date)}
                            </span>
                          </p>
                        </div>
                        <div className="col-sm-4 text-right">
                          <div className="row">
                            <div className="actionli">

                              {data.is_cancelled === 1 || data.status === 0 || data.status === 4 || data.payment_status === 0 ? <></> : <>
                                <Link
                                  to={`/edit_details?orderID=${orderID}`}
                                  target="_blank"
                                  className="btn btn-danger"


                                >
                                  <i className="mdi mdi-pencil vsalign"></i>{" "}
                                  Edit
                                </Link>
                                &nbsp;&nbsp;

                              </>}


                              {data &&
                                data.is_cancelled === 0 &&
                                data.status !== 4 ? (
                                <>


                                  <button
                                    type="submit"
                                    className="btn btn-danger"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleCancelOrderClick();
                                    }}
                                  >
                                    <i className="mdi mdi-close vsalign"></i>{" "}
                                    Cancel Order
                                  </button>
                                </>
                              ) : (
                                data &&
                                data.is_cancelled === 1 && (
                                  <>
                                    <div className="badge badge-danger">
                                      Order Cancelled
                                      {/* <span
                                            className="mx-2"
                                            data-title={data.cancelled_reason}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <i className="fa fa-info-circle"></i>
                                          </span> */}
                                    </div>

                                    <p className="mb-0">
                                      Cancelled Reason :{" "}
                                      {data.cancelled_reason}
                                    </p>
                                  </>
                                )
                              )}

                              &nbsp;&nbsp;


                              <button
                                type="button"
                                className="btn btn-warning"
                                onClick={handlePrintBillClick}
                              >
                                <i className="mdi mdi-printer vsalign"></i>{" "}
                                Print BILL
                              </button>
                              &nbsp;
                              {data &&
                                data.status !== 0 &&
                                data.status !== 4 && (
                                  <button
                                    type="button"
                                    className="btn btn-dark"
                                    // onClick={handlePrintKOT}
                                    onClick={handlePrintKOTClick}
                                  >
                                    <i className="mdi mdi-printer vsalign"></i>{" "}
                                    Print KOT
                                  </button>
                                )}
                              &nbsp;
                              <span
                                className="btn btn-danger"
                                onClick={handleBackClick}
                              >
                                <i className="menu-icon mdi mdi-arrow-left vsalign"></i>
                                Go Back
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row flex-grow">
                        <div className="col-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <div className="d-sm-flex justify-content-between align-items-start mb-3 liveorder">
                                <div className="">
                                  {data &&
                                    data.payment_status === 0 &&
                                    data.is_cancelled === 0 &&
                                    data.status !== 4 && (
                                      <>
                                        {data && data.status === 0 && (
                                          <>
                                            <button
                                              type="submit"
                                              className="btn btn-success text-white"
                                              onClick={(e) =>
                                                handleChangeOrderStatus(
                                                  e,
                                                  orderID,
                                                  1
                                                )
                                              }
                                            >
                                              Accept
                                            </button>
                                            &nbsp;
                                            <button
                                              type="submit"
                                              className="btn btn-danger"
                                              onClick={(e) =>
                                                handleChangeOrderStatus(
                                                  e,
                                                  orderID,
                                                  4
                                                )
                                              }
                                            >
                                              Reject
                                            </button>
                                          </>
                                        )}
                                        {data && data.status === 1 && (
                                          <button
                                            type="submit"
                                            className="btn btn-success text-white"
                                            onClick={(e) =>
                                              handleChangeOrderStatus(
                                                e,
                                                orderID,
                                                2
                                              )
                                            }
                                          >
                                            Mark as Order Ready
                                          </button>
                                        )}
                                      </>
                                    )}


                                </div>

                                <div className="">
                                  {/* {data && 
                                  
                                    data.payment_status === 0 &&
                                    data.status !== 4 &&
                                    data.is_cancelled === 0 &&
                                    data.status !== 0  && (
                                    <div>

                                      <div className="badge badge-opacity-danger kr-mouse-pointer">
                                         Unpaid
                                         
                                      </div>
                                      {" "}

                                      <button
                                        type="submit"
                                        className="btn btn-info"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          // handlePaymentMethodClick("cash");
                                        }}
                                      >
                                        Mark as Paid
                                      </button>


                                    </div>
                                  )} */}


                                  {data.payment_status === 2 &&
                                    <div>

                                      <div className="badge badge-opacity-warning kr-mouse-pointer mx-2" title={data.holdReason && data.holdReason}>
                                        Hold <i className="fa fa-info-circle " ></i>
                                      </div>
                                      {" "}

                                      {data &&
                                        data.status !== 4 &&
                                        data.is_cancelled === 0 &&
                                        data.status !== 0 &&
                                        <div>
                                          <button
                                            type="submit mx-2"
                                            className="btn btn-info"
                                            onClick={(e) => {
                                              setPartialPaymentlModel(true)
                                            }}
                                          >
                                            Mark as Paid
                                          </button>{" "}
                                          {data && data.order_type === "dine_in" && data.is_cancelled !== 1 && data.status !== 0 && data.status !== 0 ? <button
                                            type="submit mx-2 my-2"
                                            className="btn btn-dark"
                                            onClick={() => {
                                              setIsOpenSpiltBill(true);
                                            }}
                                          >
                                            Split Bill
                                          </button> : <></>}

                                        </div>
                                      }
                                    </div>

                                  }

                                  {data.payment_status === 0 &&
                                    <div>
                                      <div className="badge badge-opacity-danger kr-mouse-pointer mx-2">
                                        Unpaid
                                      </div>
                                      {" "}
                                      {data &&
                                        data.payment_status === 0 &&
                                        data.status !== 4 &&
                                        data.is_cancelled === 0 &&
                                        data.status !== 0 &&
                                        (<>
                                          <button
                                            type="submit mx-2"
                                            className="btn btn-info"
                                            onClick={(e) => {
                                              setPartialPaymentlModel(true)
                                            }}
                                          >
                                            Mark as Paid
                                          </button>{" "}
                                          {data && data.order_type === "dine_in" && data.is_cancelled !== 1 && data.status !== 0 && data.status !== 0 ? <button
                                            type="submit mx-2 my-2"
                                            className="btn btn-dark"
                                            onClick={() => {
                                              setIsOpenSpiltBill(true);
                                            }}
                                          >
                                            Split Bill
                                          </button> : <></>}
                                        </>)
                                      }




                                    </div>

                                  }



                                  {data.payment_status === 1 &&
                                    <div className="badge badge-opacity-success kr-mouse-pointer" onClick={() => { setPartialPaymentDetailModel(true) }} title="Click here to view details">

                                      Paid
                                      {" "}
                                      <i className="fa fa-info-circle " ></i>
                                    </div>

                                  }

                                  {data.payment_status === 3 &&
                                    <div>
                                      <div className="badge badge-opacity-dark kr-mouse-pointer partially-paid-bg-collor" onClick={() => { setPartialPaymentDetailModel(true) }} title="Click here to view details">
                                        Partially Paid
                                        {" "}
                                        <i className="fa fa-info-circle " ></i>
                                      </div>{" "}
                                      {data && data.order_type === "dine_in" && data.is_cancelled !== 1 && data.status !== 0 && data.status !== 0 ? <button
                                        type="submit mx-2 my-2"
                                        className="btn btn-dark"
                                        onClick={() => {
                                          setIsOpenSpiltBill(true);
                                        }}
                                      >
                                        Split Bill
                                      </button> : <></>}
                                    </div>

                                  }
                                  {/* {data &&
                                    data.is_cancelled === 0 &&
                                    data.status !== 4 ? (
                                    <>
                                      &nbsp;
                                      <button
                                        type="submit"
                                        className="btn btn-danger"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleCancelOrderClick();
                                        }}
                                      >
                                        Cancel Order
                                      </button>
                                    </>
                                  ) : (
                                    data &&
                                    data.is_cancelled === 1 && (
                                      <>
                                        <div className="badge badge-danger">
                                          Order Cancelled
                                          <span
                                            className="mx-2"
                                            data-title={data.cancelled_reason}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <i className="fa fa-info-circle"></i>
                                          </span>
                                        </div>

                                        <p className="mb-0">
                                          Cancelled Reason :{" "}
                                          {data.cancelled_reason}
                                        </p>
                                      </>
                                    )
                                  )} */}

                                </div>
                              </div>

                              <div className="d-sm-flex justify-content-between align-items-start mb-3">
                                <div className="">
                                  <h4 className="card-title card-title-dash">
                                    {data && data.fooders_name}
                                  </h4>
                                  <p>
                                    {data && data.f_landline && (
                                      <>
                                        <span className="mdi mdi-phone"></span>{" "}
                                        Phone: {data.f_landline}
                                        <br />
                                      </>
                                    )}
                                    {data && data.f_address && (
                                      <>
                                        <span className="mdi mdi-office-building"></span>
                                        {data.f_address} <br />
                                        {data &&
                                          (data.f_city ||
                                            data.f_state ||
                                            data.f_zipcode) && (
                                            <>
                                              <span className="mdi mdi-home-account"></span>
                                              {data.f_city &&
                                                `${data.f_city} ,`}{" "}
                                              {data.f_state &&
                                                capitalizeFirstLetter(
                                                  data.f_state
                                                )}{" "}
                                              {data.f_zipcode &&
                                                ` , ${data.f_zipcode}`}
                                            </>
                                          )}
                                      </>
                                    )}
                                  </p>

                                </div>
                                <div className="">
                                  {data && data.eater_name && (
                                    <h4 className="card-title card-title-dash">
                                      {data.eater_name}
                                    </h4>
                                  )}

                                  {data && data.eater_phonenumber && (
                                    <p>
                                      <span className="mdi mdi-phone"></span>{" "}
                                      Phone:
                                      {data.eater_phonenumber}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="table-responsive">
                                <table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th>S.No.</th>
                                      <th style={{ width: "50%" }}>Product</th>
                                      <th>Quantity</th>
                                      <th>Price</th>
                                      <th>Total</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data &&
                                      data.details &&
                                      data.details.map((item, index) => (
                                        <tr key={index}>
                                          <td className="py-1">{index + 1}</td>
                                          <td>
                                            {item.product_name}
                                            {/* <Link
                                              onClick={(e) =>
                                                handleProductNameClick(
                                                  e,
                                                  item.product_id
                                                )
                                              }
                                            >
                                              {item.product_name}
                                            </Link> */}

                                            <p className="kr-none">

                                              {item.variant_details && <>
                                                {JSON.parse(item.variant_details).combination_details && JSON.parse(item.variant_details).combination_details.map((i) => (
                                                  <><b>{i.attribute_name}: </b> {i.attribute_value_name} <br /></>
                                                ))}
                                              </>}



                                              {item.addons_items_details && JSON.parse(item.addons_items_details).map((i, no) => (
                                                <><b>{no + 1}.</b> {i.addon_item_name} <br /></>
                                              ))}

                                            </p>

                                            {/* <p className="kr-none">
                                             
                                            </p> */}

                                          </td>
                                          <td>{item.quantity}</td>
                                          <td>
                                            {currencySymbol}{" "}
                                            {item.product_proprice
                                              ? isValidPrice(
                                                item.product_proprice
                                              )
                                              : isValidPrice(
                                                item.product_price
                                              )}
                                          </td>
                                          <td>
                                            {currencySymbol}{" "}
                                            {item.product_proprice
                                              ? isValidPrice(
                                                item.product_proprice *
                                                item.quantity
                                              ).toFixed(2)
                                              : isValidPrice(
                                                item.product_price *
                                                item.quantity
                                              ).toFixed(2)}
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>

                              <div className="text-right amtdiv">
                                <h4 className="">
                                  Subtotal &nbsp;&nbsp;
                                  <span className="amount">
                                    {currencySymbol}
                                    {subTotal}
                                  </span>
                                </h4>
                                {discountRate &&
                                  data.discount_type === "percent" && (
                                    <h4 className="">
                                      Discount ({discountRate}%) &nbsp;&nbsp;
                                      <span className="amount">
                                        - {currencySymbol}
                                        {discountAmount}
                                      </span>
                                    </h4>
                                  )}

                                {discountRate &&
                                  data.discount_type === "amount" && (
                                    <h4 className="">
                                      Discount ({currencySymbol}) &nbsp;&nbsp;
                                      <span className="amount">
                                        - {currencySymbol}
                                        {discountAmount}
                                      </span>
                                    </h4>
                                  )}

                                {SCHRate !== 0 && (
                                  <h4 className="">
                                    {data.service_charge_details &&
                                      data.service_charge_details.name}
                                    ({SCHRate}
                                    %) &nbsp;&nbsp;
                                    <span className="amount">
                                      {currencySymbol}
                                      {schAmount}
                                    </span>
                                  </h4>
                                )}

                                {/* {taxDetails &&
                                  taxDetails.length > 0 &&
                                  taxDetails.map((item, index) => (
                                    <h4 key={index} className="">
                                      {item.name}({item.percentage}%)
                                      &nbsp;&nbsp;
                                      <span className="amount">
                                        {currencySymbol}
                                        {item.amount}
                                      </span>
                                    </h4>
                                  ))} */}

                                {/* {CGSTRate !== 0 && (
                                  <h4 className="">
                                    CGST({CGSTRate}%) &nbsp;&nbsp;
                                    <span className="amount">
                                      {currencySymbol}
                                      {cgstAmount}
                                    </span>
                                  </h4>
                                )}

                                {SGSTRate !== 0 && (
                                  <h4 className="">
                                    SGST({SGSTRate}%) &nbsp;&nbsp;
                                    <span className="amount">
                                      {currencySymbol}
                                      {sgstAmount}
                                    </span>
                                  </h4>
                                )} */}


                                {CGSTTaxT2 !== 0 && (
                                  <h4 className="">
                                    CGST(2.5%) &nbsp;&nbsp;
                                    <span className="amount">
                                      {currencySymbol}
                                      {CGSTTaxT2}
                                    </span>
                                  </h4>
                                )}

                                {SGSTTaxT2 !== 0 && (
                                  <h4 className="">
                                    SGST(2.5%) &nbsp;&nbsp;
                                    <span className="amount">
                                      {currencySymbol}
                                      {SGSTTaxT2}
                                    </span>
                                  </h4>
                                )}

                                {CGSTTaxT3 !== 0 && (
                                  <h4 className="">
                                    CGST(6%) &nbsp;&nbsp;
                                    <span className="amount">
                                      {currencySymbol}
                                      {CGSTTaxT3}
                                    </span>
                                  </h4>
                                )}

                                {SGSTTaxT3 !== 0 && (
                                  <h4 className="">
                                    SGST(6%) &nbsp;&nbsp;
                                    <span className="amount">
                                      {currencySymbol}
                                      {SGSTTaxT3}
                                    </span>
                                  </h4>
                                )}


                                {CGSTTaxT4 !== 0 && (
                                  <h4 className="">
                                    CGST(9%) &nbsp;&nbsp;
                                    <span className="amount">
                                      {currencySymbol}
                                      {CGSTTaxT4}
                                    </span>
                                  </h4>
                                )}

                                {SGSTTaxT4 !== 0 && (
                                  <h4 className="">
                                    SGST(9%) &nbsp;&nbsp;
                                    <span className="amount">
                                      {currencySymbol}
                                      {SGSTTaxT4}
                                    </span>
                                  </h4>
                                )}


                                {CGSTTaxT5 !== 0 && (
                                  <h4 className="">
                                    CGST(14%) &nbsp;&nbsp;
                                    <span className="amount">
                                      {currencySymbol}
                                      {CGSTTaxT5}
                                    </span>
                                  </h4>
                                )}

                                {SGSTTaxT5 !== 0 && (
                                  <h4 className="">
                                    SGST(14%) &nbsp;&nbsp;
                                    <span className="amount">
                                      {currencySymbol}
                                      {SGSTTaxT5}
                                    </span>
                                  </h4>
                                )}

                                {cartProductPackingCharges !== 0 && data.order_type !== "dine_in" && (
                                  <h4 className="">
                                    Packaging Charge &nbsp;&nbsp;
                                    <span className="amount">
                                      {currencySymbol}
                                      {cartProductPackingCharges}
                                    </span>
                                  </h4>
                                )}

                                {data &&
                                  data.delivery_charge &&
                                  parseFloat(data.delivery_charge) > 0 && (
                                    <h4 className="">
                                      Delivery Charge &nbsp;&nbsp;
                                      <span className="amount">
                                        {currencySymbol}
                                        {parseFloat(
                                          data.delivery_charge
                                        ).toFixed(2)}
                                      </span>
                                    </h4>
                                  )}

                                {data &&
                                  data.packaging_fee &&
                                  parseFloat(data.packaging_fee) > 0 && (
                                    <h4 className="">
                                      Packaging Fee &nbsp;&nbsp;
                                      <span className="amount">
                                        {currencySymbol}
                                        {parseFloat(data.packaging_fee).toFixed(
                                          2
                                        )}
                                      </span>
                                    </h4>
                                  )}
                                {data &&
                                  data.round_up_amount &&
                                  parseFloat(data.round_up_amount) !==
                                  0 ?
                                  <h4 className="">
                                    Round off Amount &nbsp;&nbsp;
                                    <span className="amount">
                                      {currencySymbol}
                                      {parseFloat(
                                        data.round_up_amount
                                      ).toFixed(2)}
                                    </span>
                                  </h4>
                                  : <></>
                                }

                                {data &&
                                  (data.payment_status === 0 || data.payment_status === 2) &&
                                  parseFloat(onlySavePaymentRoundUpAmount) !==
                                  0 ?
                                  <h4 className="">
                                    Round off Amount &nbsp;&nbsp;
                                    <span className="amount">
                                      {currencySymbol}
                                      {parseFloat(
                                        onlySavePaymentRoundUpAmount
                                      ).toFixed(2)}
                                    </span>
                                  </h4>
                                  : <></>
                                }

                                <h3 className="mt-2 fw-bold">
                                  Total Amount : &nbsp;&nbsp;
                                  {currencySymbol}
                                  {/* {totalAmountPaid} */}

                                  {(parseFloat(totalAmountPaid) + parseFloat(onlySavePaymentRoundUpAmount)).toFixed(2)}
                                </h3>
                              </div>

                              <h6>Eater Suggestions*</h6>
                              {data.eater_suggestions && (
                                <p>{data.eater_suggestions}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              isCancelOrderModal ? "modal fade show d-block" : "modal fade"
            }
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content w-85">
                <div className="modal-header py-2">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Are you sure ? You want to cancel #
                    {data && data.order_number_qrcode} order ?
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setIsCancelOrderModal(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body py-2">
                  <div className="container">
                    <div className="row">
                      <div className="row my-2">
                        <div className="col-sm-2 d-flex align-items-center">
                          <h5>Reason</h5>
                        </div>
                        <div className="col-sm-10">
                          <div className="form-group mb-0">
                            <textarea
                              className="form-control"
                              placeholder="Enter Reason"
                              rows={4}
                              value={cancelReason}
                              onChange={handleCancelOrderReasonChange}
                              maxLength={maxCharacterLimit}
                            ></textarea>
                            <p className="text-right m-0">
                              {cancelReason.length}/{maxCharacterLimit}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer py-1">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => {
                      setIsCancelOrderModal(false);
                    }}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleCancelOrder}
                  >
                    Cancel Order
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className={isModal ? "modal fade show d-block" : "modal fade"}
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content w-85">
                <div className="modal-header py-2">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Order #{data && data.order_number_qrcode} KOT's
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setIsModal(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body py-2">
                  <div
                    className="filter_scrl ps-2 mt-2"
                    style={{ height: "70vh" }}
                  >
                    <div className="tablenames">
                      <>
                        <div>
                          <div className="row flex-grow">
                            {dataKOTDetails.length === 0 ? (
                              <>
                                <div>No KOT Data Found</div>
                              </>
                            ) : (
                              <div>
                                <div className="row stretch-card mr10">
                                  {dataKOTDetails.map((item, index) => (
                                    <div
                                      key={index}
                                      className="col-lg-4 stretch-card"
                                    >
                                      <div className="card card-rounded grid-margin">
                                        <div className="card-body">
                                          <div className="row">
                                            <div className="col-12 text-center">
                                              <h6 className="fw-bold">
                                                {item.kot}
                                              </h6>
                                            </div>
                                            <div className="col-12 text-center">
                                              {item.table_no ? (
                                                <h6 className="fw-bold">
                                                  {/* Table No -  */}
                                                  {item.table_no}
                                                </h6>
                                              ) : (
                                                <h6 className="fw-bold">
                                                  {item.order_number
                                                    ? `#${item.order_number}`
                                                    : ""}
                                                </h6>
                                              )}
                                            </div>
                                            <div className="col-6 text-left">
                                              <h6 className="fw-bold">
                                                {item.order_type}
                                              </h6>
                                            </div>
                                            <div className="col-6 text-right">
                                              <h6 className="fw-bold">
                                                {item.time}
                                              </h6>
                                            </div>
                                          </div>
                                          <div className="row mt-2">
                                            <div className="col-5">
                                              <p>Item</p>
                                            </div>
                                            <div className="col-2 text-center">
                                              <p>Qty</p>
                                            </div>
                                            <div className="col-4 text-center">
                                              <p>Price</p>
                                            </div>
                                          </div>
                                          <div className="crdscrl">
                                            {item.final_kot_details.map(
                                              (kotItem, index) => (
                                                <div
                                                  key={index}
                                                  className="row"
                                                >
                                                  <div className="col-5">
                                                    <p>
                                                      {capitalizeFirstLetter(
                                                        kotItem.product_name
                                                      )}
                                                    </p>
                                                  </div>
                                                  <div className="col-2 text-center">
                                                    <p>{kotItem.quantity}</p>
                                                  </div>
                                                  <div className="col-4 text-center">
                                                    <p>
                                                      {currencySymbol}
                                                      {kotItem.product_proprice
                                                        ? isValidPrice(
                                                          kotItem.product_proprice
                                                        )
                                                        : isValidPrice(
                                                          kotItem.product_price
                                                        )}
                                                    </p>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                          <div className="row mt-2">
                                            <div className="col-lg-12 text-center">
                                              <button
                                                type="submit"
                                                className="btn btn-success"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setPrintKOTDetails2(item);
                                                }}
                                              >
                                                Print
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
                <div className="modal-footer py-1">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => {
                      setIsModal(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className={isBillsModal ? "modal fade show d-block" : "modal fade"}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog  " role="document">
              <div className="modal-content w-85" >
                <div className="modal-header py-2">
                  <h5 className="modal-title"    >
                    Order #{data && data.order_number_qrcode} Bill's
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setIsBillsModal(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body py-2">
                  <div
                    className="filter_scrl ps-2 mt-2"
                    style={{ height: "30vh" }}
                  >
                    <div className="tablenames">
                      <>
                        <div>
                          <div className="row flex-grow">
                            <div>
                              <div className="row stretch-card mr10">
                                <div className="col-lg-4 stretch-card custom-cursor-pointer">
                                  <div className="card card-rounded grid-margin">
                                    <div className="card-body" onClick={(e) => {
                                      e.preventDefault();
                                      handlePrintBill();
                                    }}>
                                      #Main
                                    </div>
                                  </div>
                                </div>

                                {dataBillDetails.map((item, index) => (
                                  <div
                                    key={index}
                                    className="col-lg-4 stretch-card custom-cursor-pointer"
                                  >
                                    <div className="card card-rounded grid-margin">
                                      <div className="card-body" onClick={(e) => {
                                        e.preventDefault();
                                        console.log(item)
                                        setPrintBillDetails2(item);
                                      }}>
                                        #{item.bill_no} Bill
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
                <div className="modal-footer py-1">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => {
                      setIsBillsModal(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>


          <div
            className={isOpenSpiltBill ? "modal fade show d-block" : "modal fade"}
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"

          >
            <div className="modal-dialog modal-lg" style={{ minWidth: "80vw" }} role="document">
              <div className="modal-content w-85"  >
                <div className="modal-header py-2">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Split Bill (Order #{data && data.order_number_qrcode})
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setIsOpenSpiltBill(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body py-2">
                  <div
                    className="filter_scrl ps-2 mt-2"
                    style={{ height: "80vh", minWidth: "70vw" }}
                  >
                    <div className="tablenames">
                      <div>

                        <div className="row stretch-card mr10">
                          {splitBillData &&
                            splitBillData.map((item) => (
                              <div className="col-1">
                                <div className="card card-rounded grid-margin">
                                  <div className={splitBillNumber === item.bill_number ? "card-body active custom-cursor-pointer" : "card-body  custom-cursor-pointer"} onClick={() => { clickOnExistsSplitBill(item.bill_number) }}>
                                    <center>
                                      #{item.bill_number} Bill
                                    </center>
                                  </div>
                                </div>
                              </div>
                            ))}

                          {splitBillData.length <= 0 ? <div className="col-4 ">
                            <div className="card card-rounded grid-margin">
                              <div className={"card-body  custom-cursor-pointer"}>
                                <center>
                                  Split bills is not available.
                                </center>
                              </div>
                            </div>
                          </div> : <></>}

                        </div>



                        <div className="row flex-grow">
                          <div>
                            <div className="row stretch-card mr10">
                              <div className="col-lg-4 stretch-card" style={{ minHeight: "65vh" }}>
                                <div className="card card-rounded grid-margin">
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-12">
                                        <h6 className="fw-bold">Order (#{data && data.order_number_qrcode}) Items</h6>

                                      

                                      </div>


                                    </div>

                                    {!checkAllItemsInSplitBill() ? 
                                    
                                    <div>


<div className="row mt-2">
                                      <div className="col-5">
                                        <p>Items</p>
                                      </div>
                                      <div className="col-3 text-center">
                                        <p>Qty</p>
                                      </div>

                                      <div className="col-4 text-center">
                                        <p>Action</p>
                                      </div>
                                    </div><hr />
                                    {data &&
                                      data.details &&
                                      data.details.map((item, index) => (
                                        <div key={index}>
                                          {!chickExistsProductSplitBill(item.id, item.quantity) ?



                                            <div className="row">
                                              <div className="col-5">
                                                <p className="kr-none"> <b>{item.product_name}</b> ({item.quantity})<br />
                                                  {item.variant_details && <>
                                                    {JSON.parse(item.variant_details).combination_details && JSON.parse(item.variant_details).combination_details.map((i) => (
                                                      <><b>{i.attribute_name}: </b> {i.attribute_value_name} <br /></>
                                                    ))}
                                                  </>}


                                                  {item.addons_items_details && JSON.parse(item.addons_items_details).map((i, no) => (
                                                    <><b>{no + 1}.</b> {i.addon_item_name} <br /></>
                                                  ))}
                                                </p>

                                              </div>
                                              <div className="col-3 text-center">
                                                <p>{item.quantity - getCountExistsProductSplitBill(item.id)}</p>
                                              </div>

                                              <div className="col-4  text-center">
                                                <button className="btn btn-success btn-sm" onClick={() => { addItemsForSplitBillCreation(item) }} disabled={chickExistsProductSplitBillCreation(item.id) || chickExistsProductSplitBill(item.id, item.quantity)}>Add</button>
                                              </div>
                                            </div>
                                            : <></>}

                                        </div>

                                      ))}
                                    </div>
                                    :  <div>
                                      

                                      <h3 className="mx10">
                                        <br/>

                                      No items are being merged.
                                      </h3>
                                      
                                      
                                      
                                      </div>}
                                    


                                  </div>
                                </div>
                              </div>
                              {splitBillCreationData.length > 0 ?
                                <div className="col-lg-4 stretch-card">
                                  <div className="card card-rounded grid-margin">
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-12">
                                          <h6 className="fw-bold">#{splitBillNumber} Items</h6>
                                        </div>

                                      </div>
                                      <div className="row mt-2">
                                        <div className="col-5">
                                          <p>Items</p>
                                        </div>
                                        <div className="col-3 text-center">
                                          <p>Qty</p>
                                        </div>
                                        <div className="col-4 text-center">
                                          <p>Action</p>
                                        </div>
                                      </div><hr />
                                      {splitBillCreationData &&

                                        splitBillCreationData.map((item) => (
                                          <div className="row">
                                            <div className="col-5">
                                              <p className="kr-none"> <b>{item.product_name}</b><br />
                                                {item.variant_details && <>
                                                  {JSON.parse(item.variant_details).combination_details && JSON.parse(item.variant_details).combination_details.map((i) => (
                                                    <><b>{i.attribute_name}: </b> {i.attribute_value_name} <br /></>
                                                  ))}
                                                </>}



                                                {item.addons_items_details && JSON.parse(item.addons_items_details).map((i, no) => (
                                                  <><b>{no + 1}.</b> {i.addon_item_name} <br /></>
                                                ))}
                                              </p>

                                            </div>
                                            <div className="col-5">
                                              <div className="">
                                                <button
                                                  type="button"
                                                  onClick={() => { updateQuantityItemsForSplitBillCreation(item.id, -1) }}
                                                  style={{ height: "25px", width: "25px", fontSize: "10px" }}
                                                >
                                                  -
                                                </button>{" "}
                                                <button
                                                  type="button"
                                                  onClick={() => { }}
                                                  style={{ height: "25px", width: "25px", fontSize: "10px" }}
                                                >
                                                  {item.quantity}
                                                </button>{" "}
                                                <button
                                                  type="button"
                                                  onClick={() => { updateQuantityItemsForSplitBillCreation(item.id, 1) }}
                                                  style={{ height: "25px", width: "25px", fontSize: "10px" }}
                                                >
                                                  +
                                                </button>


                                              </div>
                                            </div>

                                            <div className="col-1  text-center">
                                              <span style={{ cursor: "pointer" }}
                                                onClick={() => { removeItemsForSplitBillCreation(item.id) }}

                                              >
                                                <i
                                                  className="fa fa-window-close closeicon"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        ))}


                                    </div>
                                  </div>
                                </div> : <></>}

                              {splitBillCreationData.length > 0 ? <div className="col-lg-4 stretch-card">
                                <div className="card card-rounded grid-margin">
                                  <div className="card-body">
                                    <h6 className="modal-title mt-2">Customer Details</h6><hr />

                                    <div className="row">
                                      <div className="col">
                                        <label className="">Mobile</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Mobile"
                                          maxLength={10}
                                          name="mobile"
                                          value={customerDataForSpiltBill.mobile}
                                          onChange={handleCustomerFormChangeForSplitBill}
                                        />
                                      </div>
                                      <div className="col">
                                        <label className="">Name</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Name"
                                          name="name"
                                          defaultValue=""
                                          value={customerDataForSpiltBill.name}
                                          onChange={handleCustomerFormChangeForSplitBill}
                                        />
                                      </div>
                                    </div>

                                    <div className="row mt-2">
                                      <div className="col">
                                        <label className="">Address</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Address"
                                          name="address"
                                          defaultValue=""
                                          value={customerDataForSpiltBill.address}
                                          onChange={handleCustomerFormChangeForSplitBill}
                                        />
                                      </div>
                                    </div>
                                    <hr />
                                    <h6 className="modal-title mt-2">#{splitBillNumber} Bill Amount: {localStorage.getItem("currencySymbol")} {parseFloat(splitBillCreationAmountData?.total).toFixed(2)}</h6>
                                    <h6 className="mt-2">Select Payment Option:</h6>


                                    <div className="row">
                                      <div className="">
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="inlineRadioOptionsForSplitBill"
                                            checked={paymentDataForSpiltBill.method === "Cash"}
                                            onChange={() => {
                                              setPaymentDataForSpiltBill({
                                                ...paymentDataForSpiltBill,
                                                ["method"]: "Cash",
                                              });
                                            }}

                                          />
                                          <label className="form-check-label">Cash</label>
                                        </div>

                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="inlineRadioOptionsForSplitBill"
                                            checked={paymentDataForSpiltBill.method === "Card"}
                                            onChange={() => {
                                              setPaymentDataForSpiltBill({
                                                ...paymentDataForSpiltBill,
                                                ["method"]: "Card",
                                              });
                                            }}
                                          />
                                          <label className="form-check-label">Card</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="inlineRadioOptionsForSplitBill"
                                            checked={paymentDataForSpiltBill.method === "NEFT"}
                                            onChange={() => {
                                              setPaymentDataForSpiltBill({
                                                ...paymentDataForSpiltBill,
                                                ["method"]: "NEFT",
                                              });
                                            }}
                                          />
                                          <label className="form-check-label">NEFT</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="inlineRadioOptionsForSplitBill"
                                            checked={paymentDataForSpiltBill.method === "UPI"}
                                            onChange={() => {
                                              setPaymentDataForSpiltBill({
                                                ...paymentDataForSpiltBill,
                                                ["method"]: "UPI",
                                              });
                                            }}
                                          />
                                          <label className="form-check-label">UPI</label>
                                        </div>


                                      </div>
                                    </div>

                                    {paymentDataForSpiltBill.method === "UPI" && (

                                      <div className="col-12  mt-3">
                                        <label className="">
                                          Select Method
                                          <span className="text-danger">*</span>
                                        </label>
                                        <select
                                          className="form-select"
                                          name="method"
                                          value={paymentDataForSpiltBill.upiType}
                                          onChange={(e) => { setPaymentDataForSpiltBill({ ...paymentDataForSpiltBill, ["upiType"]: e.target.value, }); }}
                                        >
                                          <option value="">Select Method</option>
                                          <option value="Google Pay">
                                            Google Pay
                                          </option>
                                          <option value="PhonePe">PhonePe</option>
                                          <option value="Paytm">Paytm</option>
                                          <option value="Other">Other</option>
                                        </select>
                                        {paymentDataForSpiltBill.upiType === "Other" && (
                                          <>
                                            &nbsp;
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Method"
                                              name="other_method"

                                            />
                                          </>
                                        )}
                                      </div>

                                    )}

                                    <div className="row mt-2">
                                      <div className="col">
                                        <label className="">Tip</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Tip"
                                          name="tip"
                                          onChange={(e) => { setPaymentDataForSpiltBill({ ...paymentDataForSpiltBill, ["tip"]: e.target.value, }); }}
                                          value={paymentDataForSpiltBill.tip}
                                        />
                                      </div>
                                    </div>



                                    <div className="row mt-2">
                                      <div className="col">
                                        <label className="">Transaction / Reference ID</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Transaction / Reference ID"

                                          onChange={(e) => { setPaymentDataForSpiltBill({ ...paymentDataForSpiltBill, ["transaction_id"]: e.target.value, }); }}
                                          value={paymentDataForSpiltBill.transaction_id}
                                        />
                                      </div>
                                    </div>
                                    <br />

                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      data-dismiss="modal"
                                      onClick={createNewSplitBill}
                                    >
                                      {splitBillNumber > splitBillData.length ? "Add Bill" : "Update Bill"}

                                    </button>

                                  </div>

                                </div>
                              </div> : <></>}


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
                <div className="modal-footer py-1">
                  <button
                    type="button"
                    className="btn btn-success"
                    data-dismiss="modal"
                    onClick={() => {
                      saveSplitBills()
                    }}
                  >
                    Save
                  </button>{" "}
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => {
                      setIsOpenSpiltBill(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: "none" }}>
            <div id="contentToPrintBill">

              <p className="name" style={{ textAlign: "end" }}>
                {data &&
                  data.invoice_number}
              </p>

              <div className="printcss">
                <div className="col-12">
                  <p className="name">
                    <strong>
                      {data && capitalizeFirstLetter(data.fooders_name)}
                    </strong>
                  </p>

                  <p className="name">

                    {data &&
                      capitalizeFirstLetter(data.Fooder_name2)}

                  </p>


                  <p className="name">
                    {data && capitalizeFirstLetter(data.f_address)}{', '}
                    {data &&
                      (data.f_city || data.f_state || data.f_zipcode) && (
                        <>
                          {data.f_city && `${data.f_city}, `}
                          {data.f_state && capitalizeFirstLetter(data.f_state)}
                          {data.f_zipcode && `, ${data.f_zipcode}`}
                        </>
                      )}
                  </p>
                  {data.f_landline ? <p className="name">Phone No :
                    {data.f_landline}

                  </p> : <></>}
                </div>

                <div className="col-12">
                  {data && data.fooders_gstin && (
                    <p className="gst thank">
                      GST Number : {data && data.fooders_gstin}
                    </p>
                  )}
                </div>

                <table className="tblecss info">

                  <tbody>


                    <tr>
                      <td>
                        Name : {" "}
                        {capitalizeFirstLetter(data.eater_name)}
                      </td>

                    </tr>

                    {data.eater_phonenumber ? <tr>
                      <td>
                        Phone : {" "}
                        {capitalizeFirstLetter(data.eater_phonenumber)}
                      </td>
                    </tr> : <></>}


                    {data.address && data.address !== '' ? <tr>
                      <td>
                        Address : {" "}
                        {capitalizeFirstLetter(data.address)}
                      </td>
                    </tr> : <></>}
                  </tbody>

                </table>

                <table className="tblecss info">
                  <tbody>
                    <tr>
                      <td>
                        Date :{" "}
                        <span>
                          {formatTimestampForDate(data.creation_date)}
                        </span>
                      </td>
                      <td className="txtrg">
                        Time :{" "}
                        <span>
                          {formatTimestampForTime(data.creation_date)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      {/* <td>
                        {data && data.order_type && (
                          <strong>
                            {data.order_type === "dine_in"
                              ? data.table_no
                              : data.order_type.toUpperCase().replace("_", " ")}
                          </strong>
                        )}
                      </td> */}
                      {data.order_type == 'dine_in' ? <>

                        <td
                          style={{
                            display: "block",
                          }}
                        >
                          Dine In(

                          {data.table_no})
                        </td>

                      </> : <></>}

                      {data.order_type == 'take_away' ? <>
                        <td
                          style={{
                            display: "block",
                          }}
                        >
                          Take Away

                        </td>

                      </> : <></>}

                      {data.order_type == 'delivery' ? <>

                        <td
                          style={{
                            display: "block",
                          }}
                        >
                          Delivery
                        </td>
                      </> : <></>}
                      <td className="txtrg">
                        Order : #{data && data.order_number_qrcode}
                      </td>
                    </tr>
                    <tr>
                      <th colSpan="2">
                        <p
                          style={{
                            textAlign: "center",
                            padding: "5px 2px",
                            margin: "0",
                            fontSize: "medium",
                          }}
                        >
                          Original Receipt
                        </p>
                      </th>
                    </tr>
                  </tbody>
                </table>
                <table className="tblecss2">
                  <thead>
                    <tr>
                      <th className="thcss itemth">Item</th>
                      <th className="thcss qtyth txtct">Qty</th>
                      <th className="thcss rateth txtrg">Rate</th>
                      <th className="thcss amtth txtrg">Amt</th>
                    </tr>
                  </thead>

                  <tbody>
                    {data &&
                      data.details &&
                      data.details.map((order, index) => (
                        <tr key={index}>
                          <td className="tdcss nametd">
                            {capitalizeFirstLetter(order.product_name)}

                            {order.variant_details && <><br /></>}
                            {order.variant_details && <>
                              {JSON.parse(order.variant_details).combination_details && JSON.parse(order.variant_details).combination_details.map((i) => (
                                <><b>{i.attribute_name}: </b> {i.attribute_value_name} <br /></>
                              ))}
                            </>}

                            {order.addons_items_details && JSON.parse(order.addons_items_details).map((i, no) => (
                              <><b>{no + 1}.</b> {i.addon_item_name} <br /></>
                            ))}

                          </td>
                          <td className="tdcss qtytd txtct">
                            {order.quantity}
                          </td>
                          <td className="tdcss ratetd txtrg">
                            {currencySymbol}
                            {order.product_proprice
                              ? isValidPrice(order.product_proprice)
                              : isValidPrice(order.product_price)}
                          </td>
                          <td className="tdcss amttd txtrg">
                            {currencySymbol}
                            {isValidPrice(
                              (order.product_proprice ? order.product_proprice : order.product_price) * order.quantity
                            ).toFixed(2)
                            }
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <table className="tblecss2">
                  <tbody>
                    <tr>
                      <td className="txtrg brtp subtl">Subtotal</td>
                      <td className="txtrg brtp">
                        {currencySymbol}
                        {subTotal}
                      </td>
                    </tr>
                    {discountRate && data.discount_type === "percent" && (
                      <tr>
                        <td className="txtrg subtl">
                          Discount ({discountRate}%)
                        </td>
                        <td className="txtrg">
                          {currencySymbol}
                          {discountAmount}
                        </td>
                      </tr>
                    )}
                    {discountRate && data.discount_type === "amount" && (
                      <tr>
                        <td className="txtrg subtl">
                          Discount ({currencySymbol})
                        </td>
                        <td className="txtrg">
                          {currencySymbol}
                          {discountAmount}
                        </td>
                      </tr>
                    )}
                    {SCHRate !== 0 && (
                      <tr>
                        <td className="txtrg subtl">
                          {data.service_charge_details &&
                            data.service_charge_details.name}
                          ({SCHRate}
                          %)
                        </td>
                        <td className="txtrg">
                          {currencySymbol}
                          {schAmount}
                        </td>
                      </tr>
                    )}
                    {/* {taxDetails &&
                      taxDetails.length > 0 &&
                      taxDetails.map((item, index) => (
                        <tr key={index}>
                          <td className="txtrg subtl">
                            {item.name}({item.percentage}%)
                          </td>
                          <td className="txtrg">
                            {currencySymbol}
                            {item.amount}
                          </td>
                        </tr>
                      ))} */}

                    {/* {CGSTRate !== 0 && (
                      <tr>
                        <td className="txtrg subtl">CGST({CGSTRate}%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {cgstAmount}
                        </td>
                      </tr>
                    )}
                    {SGSTRate !== 0 && (
                      <tr>
                        <td className="txtrg subtl">SGST({SGSTRate}%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {sgstAmount}
                        </td>
                      </tr>
                    )} */}


                    {CGSTTaxT2 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">CGST(2.5%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {CGSTTaxT2}
                        </td>
                      </tr>)
                    }

                    {SGSTTaxT2 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">SGST(2.5%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {SGSTTaxT2}
                        </td>
                      </tr>)
                    }


                    {CGSTTaxT3 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">CGST(6%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {CGSTTaxT3}
                        </td>
                      </tr>)
                    }

                    {SGSTTaxT3 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">SGST(6%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {SGSTTaxT3}
                        </td>
                      </tr>)
                    }


                    {CGSTTaxT4 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">CGST(9%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {CGSTTaxT4}
                        </td>
                      </tr>)
                    }

                    {SGSTTaxT4 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">SGST(9%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {SGSTTaxT4}
                        </td>
                      </tr>)
                    }


                    {CGSTTaxT5 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">CGST(14%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {CGSTTaxT5}
                        </td>
                      </tr>)
                    }

                    {SGSTTaxT5 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">SGST(14%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {SGSTTaxT5}
                        </td>
                      </tr>)
                    }

                    {cartProductPackingCharges !== 0 && data.order_type !== "dine_in" &&
                      (<tr>
                        <td className="txtrg subtl">Packaging Charges</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {cartProductPackingCharges}
                        </td>
                      </tr>)
                    }





                    {data &&
                      data.delivery_charge &&
                      parseFloat(data.delivery_charge) > 0 && (
                        <tr>
                          <td className="txtrg subtl">Delivery Charge</td>
                          <td className="txtrg">
                            {currencySymbol}
                            {parseFloat(data.delivery_charge).toFixed(2)}
                          </td>
                        </tr>
                      )}
                    {data &&
                      data.packaging_fee &&
                      parseFloat(data.packaging_fee) > 0 && (
                        <tr>
                          <td className="txtrg subtl">Packaging Fee</td>
                          <td className="txtrg">
                            {currencySymbol}
                            {parseFloat(data.packaging_fee).toFixed(2)}
                          </td>
                        </tr>
                      )}


                    {data &&
                      data.round_up_amount &&
                      parseFloat(data.round_up_amount) !== 0 ? <>
                      <tr>
                        <td className="txtrg subtl">Round off</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {parseFloat(data.round_up_amount).toFixed(2)}
                        </td>
                      </tr>
                    </> : <></>}

                    {data &&
                      (data.payment_status === 0 || data.payment_status === 2) &&
                      parseFloat(onlySavePaymentRoundUpAmount) !== 0 ? <>
                      <tr>
                        <td className="txtrg subtl">Round off</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {parseFloat(onlySavePaymentRoundUpAmount).toFixed(2)}
                        </td>
                      </tr>
                    </> : <></>}

                    <tr>
                      <th className="tlt">Total</th>
                      <th className="tlt txtrg pd0">
                        {currencySymbol}
                        {/* {totalAmountPaid} */}
                        {(parseFloat(totalAmountPaid) + parseFloat(onlySavePaymentRoundUpAmount)).toFixed(2)}
                      </th>
                    </tr>
                  </tbody>
                </table>
                <table className="tblecss">
                  <tbody>
                    <tr>
                      <td>
                        {data && data.fooders_fssai_number && (
                          <p className="thank">
                            FSSAI Number : {data.fooders_fssai_number}
                          </p>
                        )}
                      </td>
                    </tr>
                    {data && <tr>
                      <td>
                        {/* <p className="thank">Paid By {data.payment_type}</p> */}
                        {data.payment_status === 0 && (<p className="thank">Unpaid</p>)}
                        {data.payment_status === 1 && (<p className="thank">Paid</p>)}
                        {data.payment_status === 3 && (<p className="thank">Partially Paid</p>)}
                        {data.payment_status === 2 && (<p className="thank">Hold</p>)}
                      </td>
                    </tr>}
                    <tr>
                      <td>
                        <p className="thank">{data.billing_notes}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="thank">Thank you for your visit!</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div style={{ display: "none" }}>
            {printBillDetails2 ? <div id="contentToPrintBill2">
              <p className="name" style={{ textAlign: "end" }}>
                {data &&
                  data.invoice_number}
              </p>

              <div className="printcss">
                <div className="col-12">
                  <p className="name">
                    <strong>
                      {data && capitalizeFirstLetter(data.fooders_name)}
                    </strong>
                  </p>

                  <p className="name">

                    {data &&
                      capitalizeFirstLetter(data.Fooder_name2)}

                  </p>


                  <p className="name">
                    {data && capitalizeFirstLetter(data.f_address)}{', '}
                    {data &&
                      (data.f_city || data.f_state || data.f_zipcode) && (
                        <>
                          {data.f_city && `${data.f_city}, `}
                          {data.f_state && capitalizeFirstLetter(data.f_state)}
                          {data.f_zipcode && `, ${data.f_zipcode}`}
                        </>
                      )}
                  </p>
                  {data.f_landline ? <p className="name">Phone No :
                    {data.f_landline}

                  </p> : <></>}
                </div>

                <div className="col-12">
                  {data && data.fooders_gstin && (
                    <p className="gst thank">
                      GST Number : {data && data.fooders_gstin}
                    </p>
                  )}
                </div>

                <table className="tblecss info">

                  <tbody>


                    <tr>
                      <td>
                        Name : {" "}
                        {capitalizeFirstLetter(printBillDetails2?.customer_data?.name)}
                      </td>

                    </tr>



                    {printBillDetails2?.customer_data?.mobile ? <tr>
                      <td>
                        Phone : {" "}
                        {capitalizeFirstLetter(printBillDetails2?.customer_data?.mobile)}

                      </td>
                    </tr> : <></>}


                    {printBillDetails2?.customer_data?.address && printBillDetails2?.customer_data?.address !== '' ? <tr>
                      <td>
                        Address : {" "}
                        {capitalizeFirstLetter(printBillDetails2?.customer_data?.address)}

                      </td>
                    </tr> : <></>}
                  </tbody>

                </table>

                <table className="tblecss info">
                  <tbody>
                    <tr>
                      <td>
                        Date :{" "}
                        <span>
                          {formatTimestampForDate(data.creation_date)}
                        </span>
                      </td>
                      <td className="txtrg">
                        Time :{" "}
                        <span>
                          {formatTimestampForTime(data.creation_date)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      {/* <td>
                        {data && data.order_type && (
                          <strong>
                            {data.order_type === "dine_in"
                              ? data.table_no
                              : data.order_type.toUpperCase().replace("_", " ")}
                          </strong>
                        )}
                      </td> */}
                      {data.order_type == 'dine_in' ? <>

                        <td
                          style={{
                            display: "block",
                          }}
                        >
                          Dine In(

                          {data.table_no})
                        </td>

                      </> : <></>}

                      {data.order_type == 'take_away' ? <>
                        <td
                          style={{
                            display: "block",
                          }}
                        >
                          Take Away

                        </td>

                      </> : <></>}

                      {data.order_type == 'delivery' ? <>

                        <td
                          style={{
                            display: "block",
                          }}
                        >
                          Delivery
                        </td>
                      </> : <></>}
                      <td className="txtrg">
                        Order : #{data && data.order_number_qrcode}
                      </td>
                    </tr>
                    <tr>
                      <th colSpan="2">
                        <p
                          style={{
                            textAlign: "center",
                            padding: "5px 2px",
                            margin: "0",
                            fontSize: "medium",
                          }}
                        >
                          Original Receipt
                        </p>
                      </th>
                    </tr>
                  </tbody>
                </table>
                <table className="tblecss2">
                  <thead>
                    <tr>
                      <th className="thcss itemth">Item</th>
                      <th className="thcss qtyth txtct">Qty</th>
                      <th className="thcss rateth txtrg">Rate</th>
                      <th className="thcss amtth txtrg">Amt</th>
                    </tr>
                  </thead>

                  <tbody>
                    {printBillDetails2 &&
                      printBillDetails2.items_data &&
                      printBillDetails2.items_data.map((order, index) => (
                        <tr key={index}>
                          <td className="tdcss nametd">
                            {capitalizeFirstLetter(order.product_name)}

                            {order.variant_details && <><br /></>}
                            {order.variant_details && <>
                              {JSON.parse(order.variant_details).combination_details && JSON.parse(order.variant_details).combination_details.map((i) => (
                                <><b>{i.attribute_name}: </b> {i.attribute_value_name} <br /></>
                              ))}
                            </>}

                            {order.addons_items_details && JSON.parse(order.addons_items_details).map((i, no) => (
                              <><b>{no + 1}.</b> {i.addon_item_name} <br /></>
                            ))}

                          </td>
                          <td className="tdcss qtytd txtct">
                            {order.quantity}
                          </td>
                          <td className="tdcss ratetd txtrg">
                            {currencySymbol}
                            {order.product_proprice
                              ? isValidPrice(order.product_proprice)
                              : isValidPrice(order.product_price)}
                          </td>
                          <td className="tdcss amttd txtrg">
                            {currencySymbol}
                            {isValidPrice(
                              (order.product_proprice ? order.product_proprice : order.product_price) * order.quantity
                            ).toFixed(2)
                            }
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <table className="tblecss2">
                  <tbody>
                    <tr>
                      <td className="txtrg brtp subtl">Subtotal</td>
                      <td className="txtrg brtp">
                        {currencySymbol}
                        {parseFloat(printBillDetails2?.amount_data?.subTotal).toFixed(2)}
                      </td>
                    </tr>
                    {printBillDetails2?.amount_data?.discountDetails && printBillDetails2?.amount_data?.discountDetails?.discountType === "0" && (
                      <tr>
                        <td className="txtrg subtl">
                          Discount ({printBillDetails2?.amount_data?.discountDetails?.discountRate}%)
                        </td>
                        <td className="txtrg">
                          {currencySymbol}
                          {parseFloat(printBillDetails2?.amount_data?.discountDetails?.discountAmount).toFixed(2)}
                        </td>
                      </tr>
                    )}
                    {printBillDetails2?.amount_data?.discountDetails && printBillDetails2?.amount_data?.discountDetails?.discountType === "1" && (
                      <tr>
                        <td className="txtrg subtl">
                          Discount ({currencySymbol})
                        </td>
                        <td className="txtrg">
                          {currencySymbol}
                          {parseFloat(printBillDetails2?.amount_data?.discountDetails?.discountAmount).toFixed(2)}
                        </td>
                      </tr>
                    )}
                    {printBillDetails2?.amount_data?.schDetails?.percentage !== 0 && (
                      <tr>
                        <td className="txtrg subtl">
                          {printBillDetails2?.amount_data?.schDetails?.name}

                          ({printBillDetails2?.amount_data?.schDetails?.percentage}
                          %)
                        </td>
                        <td className="txtrg">
                          {currencySymbol}
                          {parseFloat(printBillDetails2?.amount_data?.schDetails?.amount).toFixed(2)}
                        </td>
                      </tr>
                    )}
                    {printBillDetails2 &&
                      printBillDetails2.amount_data &&
                      printBillDetails2.amount_data?.taxDetails &&
                      printBillDetails2.amount_data.taxDetails.map((tax, index) => (
                        <tr index={index}>
                          <td className="txtrg subtl">{tax.name}({tax.percentage}%)</td>
                          <td className="txtrg">
                            {currencySymbol}
                            {parseFloat(tax.amount).toFixed(2)}
                          </td>
                        </tr>

                      ))}








                    {data &&
                      data.round_up_amount &&
                      parseFloat(data.round_up_amount) !== 0 ? <>
                      <tr>
                        <td className="txtrg subtl">Round off</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {parseFloat(data.round_up_amount).toFixed(2)}
                        </td>
                      </tr>
                    </> : <></>}



                    <tr>
                      <th className="tlt">Total</th>
                      <th className="tlt txtrg pd0">
                        {currencySymbol}
                        {/* {totalAmountPaid} */}
                        {(parseFloat(printBillDetails2?.amount_data?.total)).toFixed(2)}
                      </th>
                    </tr>
                  </tbody>
                </table>
                <table className="tblecss">
                  <tbody>
                    <tr>
                      <td>
                        {data && data.fooders_fssai_number && (
                          <p className="thank">
                            FSSAI Number : {data.fooders_fssai_number}
                          </p>
                        )}
                      </td>
                    </tr>
                    {data && <tr>
                      <td>
                        {/* <p className="thank">Paid By {data.payment_type}</p> */}
                        {data.payment_status === 0 && (<p className="thank">Unpaid</p>)}
                        {data.payment_status === 1 && (<p className="thank">Paid</p>)}
                        {data.payment_status === 3 && (<p className="thank">Partially Paid</p>)}
                        {data.payment_status === 2 && (<p className="thank">Hold</p>)}
                      </td>
                    </tr>}
                    <tr>
                      <td>
                        <p className="thank">{data.billing_notes}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="thank">Thank you for your visit!</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> : <></>}

          </div>

          <div style={{ display: "none" }}>
            <div id="contentToPrintKOT">
              <div className="printcss">
                {/* <div className="col-12">
                  <p className="name">
                    <strong>
                      {data && capitalizeFirstLetter(data.fooders_name)}
                    </strong>
                  </p>
                </div> */}
                {/* <div className="col-12">
                  {data && data.fooders_gstin && (
                    <p className="gst thank">
                      GST Number : {data && data.fooders_gstin}
                    </p>
                  )}
                </div> */}

                <table className="tblecss info">
                  <tbody>
                    <tr>
                      <td>
                        Date :{" "}
                        <span>
                          {formatTimestampForDate(data.creation_date)}
                        </span>
                      </td>
                      <td className="txtrg">
                        Time :{" "}
                        <span>
                          {formatTimestampForTime(data.creation_date)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {data && data.order_type && (
                          <strong>
                            {data.order_type === "dine_in"
                              ? data.table_no
                              : data.order_type.toUpperCase().replace("_", " ")}
                          </strong>
                        )}
                      </td>
                      <td className="txtrg">
                        Order : #{data && data.order_number}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="tblecss2">
                  <thead>
                    <tr>
                      <th className="thcss itemth">Item</th>
                      <th className="thcss qtyth txtrg">Qty</th>
                      {/* <th className="thcss rateth txtrg">Rate</th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {data &&
                      data.details &&
                      data.details.map((order, index) => (
                        <tr key={index}>
                          <td className="tdcss nametd">
                            {capitalizeFirstLetter(order.product_name)}
                            <br />
                            <i>
                              {order.product_special_note}
                            </i>
                          </td>
                          <td className="tdcss qtytd txtrg">
                            {order.quantity}
                          </td>
                          {/* <td className="tdcss ratetd txtrg">
                            {currencySymbol}{" "}
                            {order.product_proprice
                              ? order.product_proprice
                              : order.product_price}
                          </td> */}
                        </tr>
                      ))}
                  </tbody>
                </table>
                <table className="tblecss">
                  <tbody>
                    <tr>
                      <td>
                        {data && data.eater_suggestions && (
                          <p className="brtpd">
                            INSTRUCTIONS : {data.eater_suggestions}
                          </p>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div style={{ display: "none" }}>
            <div id="contentToPrintKOT2">
              <div className="printcss">

                <div className="col-12">
                  {printKOTDetails2 && (
                    <p className="gst thank">
                      <strong>{printKOTDetails2.kot}</strong>
                    </p>
                  )}
                </div>

                <table className="tblecss info">
                  <tbody>
                    <tr>
                      <td>
                        Date :{" "}
                        <span>
                          {printKOTDetails2 &&
                            printKOTDetails2.date &&
                            formatTimestampForDate(printKOTDetails2.date)}
                        </span>
                      </td>
                      <td className="txtrg">
                        Time :{" "}
                        <span>{printKOTDetails2 && printKOTDetails2.time}</span>
                      </td>
                    </tr>
                    <tr>
                      {printKOTDetails2 && printKOTDetails2.order_type && (
                        <td>
                          <strong>
                            {printKOTDetails2.order_type === "DINE IN"
                              ?
                              // `Table No: ${printKOTDetails2.table_no}`
                              `${printKOTDetails2.table_no}`
                              : printKOTDetails2.order_type}


                          </strong>
                        </td>
                      )}

                      <td className="txtrg">
                        Order : #
                        {printKOTDetails2 && printKOTDetails2.order_number}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="tblecss2">
                  <thead>
                    <tr>
                      <th className="thcss itemth">Item</th>
                      {/* <th className="thcss qtyth txtct">Special Note</th> */}
                      <th className="thcss qtyth txtrg">Qty</th>
                    </tr>
                  </thead>

                  <tbody>
                    {printKOTDetails2 &&
                      printKOTDetails2.final_kot_details.map((order, index) => (
                        <tr key={index}>
                          <td className="tdcss nametd">
                            {capitalizeFirstLetter(order.product_name)}

                            {/* {order.selectedvariants.combination_details && order.selectedvariants.combination_details.map((i) => (
                              <> <br /><i>{i.attribute_name} :  {i.attribute_value_name} </i> </>
                            ))} */}

                            {/* {order.variant_details && <>
                              {JSON.parse(order.variant_details).combination_details && JSON.parse(order.variant_details).combination_details.map((i) => (
                                <><b>{i.attribute_name}: </b> {i.attribute_value_name} <br /></>
                              ))}
                            </>} */}

                            {order.selectedvariants && order.selectedvariants.combination_details && order.selectedvariants.combination_details.map((i) => (
                              <> <br /><i>{i.attribute_name} :  {i.attribute_value_name} </i> </>
                            ))}

                            {order.selectedAddons && order.selectedAddons.map((i) => (
                              <> <br /> <i>{i.addon_item_name}</i></>
                            ))}

                            {order.product_special_note ? <> <br /> <i> {order.product_special_note} </i> </> : <></>}

                          </td>

                          {/* <td className="tdcss qtytd txtct">
                            {order.product_special_note}
                          </td> */}
                          <td className="tdcss qtytd txtrg">
                            {order.quantity}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default OrderDetails;
